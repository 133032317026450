import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { environment } from "../environments/environment";

import { LayoutModule } from "@angular/cdk/layout";

import { MatBadgeModule } from "@angular/material/badge";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import {
  MatSnackBarModule,
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
} from "@angular/material/snack-bar";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { TurnIndicatorComponent } from "./turn-indicator/turn-indicator.component";
import { CaptureTrayComponent } from "./capture-tray/capture-tray.component";
import { GameBoardComponent } from "./game-board/game-board.component";
import { HistoryControlComponent } from "./history-control/history-control.component";
import { GamePieceComponent } from "./game-piece/game-piece.component";
import { HomeComponent } from "./home/home.component";
import { GameComponent } from "./game/game.component";
import { ProfileComponent } from "./profile/profile.component";
import { SettingsDialogComponent } from "./settings-dialog/settings-dialog.component";
import { AuthenticationDialogComponent } from "./authentication-dialog/authentication-dialog.component";
import { NewGameComponent } from "./new-game/new-game.component";
import { JoinGameComponent } from "./join-game/join-game.component";
import { UploadAvatarDialogComponent } from "./upload-avatar-dialog/upload-avatar-dialog.component";
import { WelcomeUserComponent } from "./welcome-user/welcome-user.component";
import { UserComponent } from "./user/user.component";
import { UCI_ENGINE_TOKEN } from "./uci-registry.service";
import { stockfishFactory } from "./stockfish.engine";
import { GOOGLE_MAPS_GEOCODER } from "./geocoder.service";

import firebase from "firebase/app";

firebase.initializeApp(environment.firebase);
if (location.hostname === "localhost") {
  firebase.firestore().useEmulator("localhost", 8080);
  firebase.auth().useEmulator("http://localhost:9099");
  //firebase.functions().useEmulator("localhost", 5001);
}

@NgModule({
  declarations: [
    AppComponent,
    TurnIndicatorComponent,
    CaptureTrayComponent,
    GameBoardComponent,
    HistoryControlComponent,
    GamePieceComponent,
    HomeComponent,
    GameComponent,
    ProfileComponent,
    SettingsDialogComponent,
    AuthenticationDialogComponent,
    NewGameComponent,
    JoinGameComponent,
    UploadAvatarDialogComponent,
    WelcomeUserComponent,
    UserComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule, // REVIEW: Replace all FormsModules w/Reactive!
    ReactiveFormsModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatBadgeModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
  ],
  providers: [
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3000 } },
    // REVIEW: Move the UCI_ENGINE_TOKEN to a NgModule in UciRegistryService?
    { provide: UCI_ENGINE_TOKEN, useValue: stockfishFactory, multi: true },
    { provide: GOOGLE_MAPS_GEOCODER, useClass: google.maps.Geocoder },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

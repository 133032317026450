import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { of, Observable } from "rxjs";

import { MatDialog } from "@angular/material/dialog";

import { Color, GameService } from "../game.service";
import { GameOptionsService, Settings } from "../game-options.service";
import { SettingsDialogComponent } from "../settings-dialog/settings-dialog.component";
import { FirestoreError } from "../firestore.service";
import { catchError, map, share, switchMap } from "rxjs/operators";
import { GameSnapshot } from "../game-update";

@Component({
  selector: "app-game",
  templateUrl: "./game.component.html",
  styleUrls: ["./game.component.scss"],
})
export class GameComponent {
  public game$: Observable<
    | { status: "error"; error: string }
    | { status: "loaded"; game: GameSnapshot }
  >;

  Color = Color;

  constructor(
    route: ActivatedRoute,
    private gameOptions: GameOptionsService,
    private dialog: MatDialog,
    gameService: GameService
  ) {
    this.game$ = route.paramMap.pipe(
      map(
        (params) =>
          // Cast since the param is part of the route; we won't get here without.
          params.get("gameId") as string
      ),
      switchMap((gameID) => gameService.observeGame(gameID)),
      map((gameSnapshot) => {
        return { status: "loaded" as const, game: gameSnapshot };
      }),
      catchError((err: unknown) => {
        return of({
          status: "error" as const,
          error: (err as FirestoreError).message,
        });
      }),
      share()
    );
  }

  openSettings(): void {
    const dialogRef = this.dialog.open(SettingsDialogComponent, {
      width: "50em",
      data: this.gameOptions.settings,
    });

    const subscription = dialogRef
      .afterClosed()
      .subscribe((result: Settings | undefined) => {
        if (result) {
          this.gameOptions.settings = result;
        }

        subscription.unsubscribe();
      });
  }

  bottomPlayer(snapshot: GameSnapshot): Color {
    if (
      this.gameOptions.bottomPlayer === "black" ||
      (this.gameOptions.bottomPlayer === "self" &&
        snapshot.playingAs === Color.Dark)
    ) {
      return Color.Dark;
    } else {
      return Color.Light;
    }
  }
}

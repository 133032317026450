<ng-container *ngIf="profile$ | async; let profile; else profileLoading">
  <ng-container [ngSwitch]="profile.state">
    <ng-container *ngSwitchCase="'no profile'">
      <ng-container *ngTemplateOutlet="profileNotFound"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'readonly'">
      <ng-container
        *ngTemplateOutlet="profileReadonly; context: profile"
      ></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'editable'">
      <ng-container
        *ngTemplateOutlet="profileEditable; context: profile"
      ></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'error'">
      <ng-container
        *ngTemplateOutlet="profileError; context: profile"
      ></ng-container>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #profileEditable let-user="user" let-userPrivate="userPrivate">
  <mat-card class="card" id="profileCard">
    <mat-card-title-group>
      <mat-card-title>
        <mat-form-field *ngIf="edit == 'displayName'; else readonlyDisplayname">
          <input
            matInput
            type="text"
            #displayName
            placeholder="Display name"
            value="{{user.displayName}}"
            (blur)="setDisplayName(displayName.value)"
            (keyup)="blurOnEnter($event)"
          />
        </mat-form-field>
        <ng-template #readonlyDisplayname>
          <div>
            <span
              >{{user.displayName !== null ? user.displayName : 'Player'}}</span
            >
            <button
              *ngIf="edit === null"
              mat-icon-button
              aria-label="Edit avitar"
              class="displayNameBtn"
              (click)="edit = 'displayName'"
            >
              <mat-icon color="accent">edit</mat-icon>
            </button>
          </div>
        </ng-template>
      </mat-card-title>
      <mat-card-subtitle
        >Joined: <span>{{user.joinDate | date:'mediumDate'}}</span>
      </mat-card-subtitle>
      <div class="avatarWrapper">
        <img
          mat-card-avatar
          src="{{user.photoURL !== null ? user.photoURL : avatarPlaceholderURL}}"
          class="avatarImg"
        />
        <button
          *ngIf="edit === null"
          mat-icon-button
          aria-label="Edit avitar"
          class="avatarBtn"
          (click)="changeAvatar()"
        >
          <mat-icon color="accent">edit</mat-icon>
        </button>
      </div>
    </mat-card-title-group>
    <mat-card-content>
      <p>
        <mat-form-field *ngIf="edit == 'email'; else readonlyEmail">
          <input
            matInput
            type="email"
            #email
            placeholder="Email"
            value="{{userPrivate.email}}"
            (blur)="setEmail(email.value)"
            (keyup)="blurOnEnter($event)"
          />
        </mat-form-field>
        <ng-template #readonlyEmail>
          <div>
            Email:
            <span>{{userPrivate.email}}</span>
            <button
              *ngIf="edit === null"
              mat-icon-button
              aria-label="Edit email"
              class="emailBtn"
              (click)="edit = 'email'"
            >
              <mat-icon color="accent">edit</mat-icon>
            </button>
          </div>
        </ng-template>
      </p>
      <mat-slide-toggle
        [checked]="user.public"
        (change)="setPublic($event.checked)"
      >
        Show profile in public listing
      </mat-slide-toggle>
      <div *ngIf="edit == 'password'; else readonlyPassword">
        <div>
          <mat-form-field>
            <input matInput type="password" placeholder="Password" #password />
          </mat-form-field>
          <p *ngIf="passwordError !== null">{{passwordError}}</p>
        </div>

        <div>
          <mat-form-field>
            <input
              matInput
              type="password"
              placeholder="Confirm password"
              #confirmPassword
            />
          </mat-form-field>
        </div>

        <button mat-stroked-button (click)="edit = null">CANCEL</button>
        <button
          mat-stroked-button
          (click)="setPassword(password.value, confirmPassword.value)"
        >
          UPDATE PASSWORD
        </button>
      </div>
      <ng-template #readonlyPassword>
        <div>
          Password: <span>********</span>
          <button
            *ngIf=" edit===null"
            mat-icon-button
            aria-label="Edit password"
            class="passwordBtn"
            (click)="edit = 'password'"
          >
            <mat-icon color="accent">edit</mat-icon>
          </button>
        </div>
      </ng-template>
    </mat-card-content>
    <mat-card-actions>
      <a mat-stroked-button (click)="location.back()">CLOSE</a>
    </mat-card-actions>
    <!-- REVIEW: Footer?  <mat-card-footer></mat-card-footer> -->
  </mat-card>
</ng-template>

<ng-template #profileReadonly let-user="user">
  <mat-card class="card">
    <mat-card-title-group>
      <mat-card-title>
        <span>{{user.displayName !== null ? user.displayName : 'Player'}}</span>
      </mat-card-title>
      <mat-card-subtitle
        >Joined: <span>{{user.joinDate | date:'mediumDate'}}</span>
      </mat-card-subtitle>
      <img
        mat-card-avatar
        *ngIf="user.photoURL !== null"
        src="{{user.photoURL}}"
      />
    </mat-card-title-group>
    <mat-card-content>
      <!-- REVIEW: show games here -->
    </mat-card-content>
    <mat-card-actions>
      <a mat-stroked-button (click)="location.back()">CLOSE</a>
    </mat-card-actions>
    <!-- REVIEW: Footer?  <mat-card-footer></mat-card-footer> -->
  </mat-card>
</ng-template>

<ng-template #profileLoading>
  <mat-card class="card">
    <mat-card-content>
      <mat-spinner class="center"></mat-spinner>
    </mat-card-content>
  </mat-card>
</ng-template>

<ng-template #profileError let-error="error">
  <mat-card class="card">
    <mat-card-title>Error</mat-card-title>
    <p>{{error}}</p>
    <mat-card-actions>
      <a mat-stroked-button (click)="location.back()">CLOSE</a>
    </mat-card-actions>
  </mat-card>
</ng-template>

<ng-template #profileNotFound>
  <mat-card class="card">
    <mat-card-title>User not found</mat-card-title>
    <mat-card-actions>
      <a mat-stroked-button (click)="location.back()">CLOSE</a>
    </mat-card-actions>
  </mat-card>
</ng-template>

import { Component, OnDestroy } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute } from "@angular/router";
import { combineLatest, Observable, of, Subject } from "rxjs";
import {
  catchError,
  delay,
  map,
  mergeMap,
  multicast,
  refCount,
  tap,
} from "rxjs/operators";

import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";

import { AuthenticationService } from "../authentication.service";
import { UploadAvatarDialogComponent } from "../upload-avatar-dialog/upload-avatar-dialog.component";
import { EnvironmentService } from "../environment.service";
import {
  FirebaseAuthError,
  FirebaseAuthService,
} from "../firebase-auth.service";
import {
  FirestoreError,
  FirestoreService,
  observableOnSnapshot,
  User,
  UserPrivate,
} from "../firestore.service";

export interface Profile {
  state: "no profile" | "readonly" | "editable" | "error";
  user: User | null;
  userPrivate: UserPrivate | null;
  error: string | null;
}

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnDestroy {
  profile$: Observable<Profile>;
  passwordError: string | null;

  private id: string;
  private _edit: null | "displayName" | "email" | "password";
  private observer: MutationObserver | null;

  constructor(
    private route: ActivatedRoute,
    public location: Location,
    public snackBar: MatSnackBar,
    private dialog: MatDialog,
    private auth: AuthenticationService,
    private environment: EnvironmentService,
    private firebaseAuth: FirebaseAuthService,
    private firestore: FirestoreService
  ) {
    this.passwordError = null;

    this.id = "bogus";
    this._edit = null;
    this.observer = null;

    this.profile$ = this.createProfileObservable();
  }

  private createProfileObservable(): Observable<Profile> {
    const userIdObservable = this.route.paramMap.pipe(
      map((paramMap) => paramMap.get("userId") as string), // Cast because userId is part of route
      tap((userId) => {
        this.id = userId;
        this.passwordError = null;
        this._edit = null;
      })
    );

    // We observe 4 things: a) authenticated user (a.1: userId paramMap, a.2: authenticated user),
    // b) User in firestore, c) UserPrivate in firestore
    let profile = userIdObservable.pipe(
      mergeMap((userId) =>
        combineLatest([
          this.auth.user.pipe(map((user) => user?.uid === userId)),
          observableOnSnapshot(this.firestore.userDoc(userId)).pipe(
            map((snapshot) => {
              return {
                err: null as FirestoreError | null,
                data: snapshot.data() ?? null,
              };
            }),
            catchError((err: FirestoreError) =>
              of({
                err: err as FirestoreError | null,
                data: null as User | null,
              })
            )
          ),
          observableOnSnapshot(this.firestore.userPrivateDoc(userId)).pipe(
            map((snapshot) => {
              return {
                err: null as FirestoreError | null,
                data: snapshot.data() ?? null,
              };
            }),
            catchError((err: FirestoreError) => {
              if (err.code === "permission-denied") {
                return of({
                  err: null as FirestoreError | null,
                  data: null as UserPrivate | null,
                });
              } else {
                return of({
                  err: err as FirestoreError | null,
                  data: null as UserPrivate | null,
                });
              }
            })
          ),
        ])
      ),
      map((args) => {
        // capture errors into the error property; Angular async pipe doesn't handle errors
        const error = args[1].err ?? args[2].err;
        if (error === null) {
          const authenticated = args[0];
          const user = args[1].data;
          const userPrivate = authenticated ? args[2].data : null;

          let state = "no profile";
          if (user !== null) {
            if (userPrivate !== null) {
              state = "editable";
            } else {
              state = "readonly";
            }
          }

          return { state, user, userPrivate, error } as Profile;
        } else {
          return {
            state: "error",
            user: null,
            userPrivate: null,
            error: error.message,
          } as Profile;
        }
      })
    );

    // Delay for debug builds
    if (this.environment.loadDelay > 0) {
      profile = profile.pipe(delay(this.environment.loadDelay));
    }

    return profile.pipe(multicast(new Subject()), refCount());
  }

  ngOnDestroy(): void {
    if (this.observer !== null) {
      this.observer.disconnect();
      this.observer = null;
    }
  }

  async setDisplayName(displayName: string | null): Promise<void> {
    try {
      if (displayName?.length === 0) {
        displayName = null;
      }

      await this.firestore
        .userDoc(this.id)
        .set({ displayName: displayName }, { merge: true });

      this.edit = null;
      const currentUser = this.firebaseAuth.currentUser;
      if (currentUser !== null && currentUser.uid === this.id) {
        currentUser
          .updateProfile({ displayName: displayName })
          .catch((error: FirebaseAuthError) =>
            console.warn("Firebase profile update failed", error.message)
          );
      }
    } catch (err: unknown) {
      console.warn(err);
      this.snackBar.open("Error saving change");
    }
  }

  async setEmail(email: string /*, secondTry?: boolean*/): Promise<void> {
    const currentUser = this.firebaseAuth.currentUser;
    if (currentUser?.uid !== this.id) {
      return;
    }

    try {
      await currentUser.updateEmail(email);
    } catch (err) {
      const error = err as FirebaseAuthError;
      console.warn(error);
      if (error.code === "auth/invalid-email") {
        this.snackBar.open("Invalid email address");
      } else if (error.code === "auth/email-already-in-use") {
        this.snackBar.open("Email in use by another account");
      } else if (error.code === "auth/requires-recent-login") {
        this.snackBar.open(
          "Please log out and log back in before changing email"
        );

        /*
        if (secondTry === undefined || !secondTry) {
          // REVIEW: Prompt just for the user password
          const cred = firebase.auth.EmailAuthProvider.credential(oldEmail, promtForPassword);
          await firebase.auth().currentUser?.reauthenticateWithCredential(cred);
          this.setEmail(email, true);
        }
        */
      }

      return;
    }

    try {
      await this.firestore
        .userPrivateDoc(this.id)
        .set({ email: email }, { merge: true });
      this.edit = null;
    } catch (err: unknown) {
      console.warn(err as FirestoreError);
      this.snackBar.open("Error saving change");
    }
  }

  async setPublic(publicProfile: boolean): Promise<void> {
    try {
      await this.firestore
        .userDoc(this.id)
        .set({ public: publicProfile }, { merge: true });

      this.edit = null;
    } catch (err: unknown) {
      const error = err as FirestoreError;
      console.warn(error);
      this.snackBar.open("Error saving change");
    }
  }

  async setPassword(password: string, confirm: string): Promise<void> {
    if (password.length < 6) {
      this.passwordError = "New password too short";
      return;
    } else if (password !== confirm) {
      this.passwordError = "Passwords do not match";
      return;
    } else {
      this.passwordError = null;
    }

    const currentUser = this.firebaseAuth.currentUser;
    if (currentUser?.uid !== this.id) {
      return;
    }

    try {
      await currentUser.updatePassword(password);
      this.edit = null;
    } catch (err: unknown) {
      this.passwordError = (err as FirebaseAuthError).message;
      console.warn(err);
    }
  }

  changeAvatar(): void {
    this.dialog.open(UploadAvatarDialogComponent, {
      autoFocus: true,
      data: this.id,
    });
  }

  blurOnEnter(event: KeyboardEvent): void {
    if (event.key === "Enter") {
      event.stopPropagation();
      const input = event.target as HTMLInputElement;
      input.blur();
    } else if (event.key === "Escape") {
      event.stopPropagation();
      this.edit = null;
    }
  }

  get edit(): null | "displayName" | "email" | "password" {
    return this._edit;
  }

  set edit(value: null | "displayName" | "email" | "password") {
    this._edit = value;

    if (this.observer !== null) {
      this.observer.disconnect();
      this.observer = null;
    }

    if (this._edit !== null) {
      // add a listener for new input elements so we can set focus.
      this.observer = new MutationObserver(
        (mutations: MutationRecord[], observer: MutationObserver) =>
          this.mutationObserver(mutations, observer)
      );

      const profileCard = document.getElementById("profileCard");
      if (profileCard !== null) {
        this.observer.observe(profileCard, {
          subtree: true,
          childList: true,
          attributes: true,
          characterData: true,
        });
      }
    }
  }

  get avatarPlaceholderURL(): string {
    return "/assets/img/avatar.png";
  }

  private mutationObserver(
    mutations: MutationRecord[],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    observer: MutationObserver
  ): void {
    for (let i = 0; i < mutations.length; i++) {
      const mutation = mutations[i];
      if (mutation.type === "childList") {
        for (let j = 0; j < mutation.addedNodes.length; j++) {
          const node = mutation.addedNodes.item(j);
          if (node === null) {
            continue;
          }

          if (node.nodeType === 1 && node.nodeName === "MAT-FORM-FIELD") {
            const input = findInput(node);
            if (input !== null) {
              input.focus();
              input.select();
            }
          }
        }
      }
    }
  }
}

function findInput(node: Node): HTMLInputElement | null {
  if (node.nodeType === 1 && node.nodeName === "INPUT") {
    return node as HTMLInputElement;
  }

  if (node.hasChildNodes()) {
    for (let i = 0; i < node.childNodes.length; i++) {
      const input = findInput(node.childNodes.item(i));
      if (input !== null) {
        return input;
      }
    }
  }

  return null;
}

import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";

import { FirebaseStorageService } from "../firebase-storage.service";
import { FirestoreService } from "../firestore.service";

@Component({
  selector: "app-upload-avatar",
  templateUrl: "./upload-avatar-dialog.component.html",
  styleUrls: ["./upload-avatar-dialog.component.scss"],
})
export class UploadAvatarDialogComponent {
  fileName: string;
  fileSize: string;
  previewURL: SafeUrl | null;
  error: string | null;

  constructor(
    private firestore: FirestoreService,
    private storage: FirebaseStorageService,
    private dialogRef: MatDialogRef<UploadAvatarDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private uid: string,
    private sanitizer: DomSanitizer
  ) {
    this.fileName = "";
    this.fileSize = "";
    this.previewURL = null;
    this.error = null;
  }

  onFileSelected(files: FileList | null): void {
    this.fileName = "";
    this.fileSize = "";
    this.previewURL = null;
    this.error = null;

    if (files === null || files.length === 0) {
      return;
    }

    const file = files[0];
    if (file.size > 2097152) {
      this.error = "Maximum filesize exceeded";
      return;
    }

    this.fileName = file.name;
    this.fileSize = toKB(file.size);

    this.previewURL = this.sanitizer.bypassSecurityTrustUrl(
      window.URL.createObjectURL(file)
    );
  }

  async upload(files: FileList | null): Promise<void> {
    if (files === null || files.length === 0) {
      return;
    }

    const file = files[0];
    const ref = this.storage.ref(
      "users/avatars/" + this.uid + "_" + this.fileName
    );

    try {
      const snapshot = await ref.put(file);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const downloadUrl: string = await snapshot.ref.getDownloadURL();

      await this.firestore
        .userDoc(this.uid)
        .set({ photoURL: downloadUrl }, { merge: true });

      this.dialogRef.close(true);
    } catch (error) {
      this.error = errorToString(error);
    }
  }
}

function toKB(bytes: number): string {
  return Math.floor(bytes / 1024).toLocaleString() + "KB";
}

function errorToString(error: { code: string; message: string }): string {
  const code: string = error.code;

  switch (code) {
    // REVIEW: there are probably specific errors we should call out!
    default:
      return "Unable to communicate with server, please try again later.";
  }
}

export const testing = { toKB, errorToString };

<img
  *ngIf="draggable"
  class="piece-{{size}}"
  src="{{imageUrl}}"
  (click)="onClick($event)"
  draggable="true"
  (dragstart)="onDragStart($event)"
/>
<img
  *ngIf="!draggable"
  class="piece-{{size}}"
  src="{{imageUrl}}"
  (click)="onClick($event)"
  draggable="false"
/>

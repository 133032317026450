import { Injectable } from "@angular/core";

import firebase from "firebase/app";
import "firebase/storage";

export type FirebaseStorageError = firebase.storage.FirebaseStorageError;

@Injectable({
  providedIn: "root",
})
export class FirebaseStorageService {
  ref(path: string): firebase.storage.Reference {
    return firebase.storage().ref(path);
  }
}

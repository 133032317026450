import { Inject, Injectable, InjectionToken } from "@angular/core";

export const GOOGLE_MAPS_GEOCODER = new InjectionToken<google.maps.Geocoder>(
  "google.maps.Geocoder"
);

export type GeocoderResult = google.maps.GeocoderResult;

@Injectable({
  providedIn: "root",
})
export class GeocoderService {
  constructor(
    @Inject(GOOGLE_MAPS_GEOCODER) private geocoder: google.maps.Geocoder
  ) {}

  geocode(lat: number, lng: number): Promise<GeocoderResult[]> {
    const promise = new Promise<GeocoderResult[]>((resolve, reject) => {
      this.geocoder.geocode(
        { location: { lat, lng } },
        (results: GeocoderResult[], status: google.maps.GeocoderStatus) => {
          if (status !== google.maps.GeocoderStatus.OK) {
            reject(status);
          } else {
            resolve(results);
          }
        }
      );
    });

    return promise;
  }
}

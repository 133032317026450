import { Component, Input } from "@angular/core";
import { Move } from "../game.service";

@Component({
  selector: "app-history-control",
  templateUrl: "./history-control.component.html",
  styleUrls: ["./history-control.component.scss"],
})
export class HistoryControlComponent {
  @Input()
  history: Move[];

  constructor() {
    this.history = [];
  }

  public moveNumberFromIndex(index: number): number {
    return Math.floor((index + 2) / 2);
  }
}

import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Color, labelFromSquare, Piece, PieceName } from "../game.service";

@Component({
  selector: "app-game-piece",
  templateUrl: "./game-piece.component.html",
  styleUrls: ["./game-piece.component.scss"],
})
export class GamePieceComponent {
  @Input()
  piece?: Piece;

  @Input()
  size: "small" | "large";

  @Input()
  draggable: boolean;

  @Input()
  file?: number;

  @Input()
  rank?: number;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output()
  click: EventEmitter<void>;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output()
  dragstart: EventEmitter<DragEvent>;

  get imageUrl(): string {
    if (this.piece !== undefined) {
      return calculateImageUrl(this.piece);
    } else {
      return "";
    }
  }

  constructor() {
    this.size = "small";
    this.draggable = false;
    this.click = new EventEmitter<void>();
    this.dragstart = new EventEmitter<DragEvent>();
  }

  onClick(event: MouseEvent): void {
    event.stopPropagation();
    this.click.emit();
  }

  onDragStart(event: DragEvent): void {
    if (event.dataTransfer !== null) {
      if (this.file === undefined || this.rank === undefined) {
        throw new Error("Draggable pieces must have file and rank set");
      }

      event.dataTransfer.setData(
        "chess/square",
        labelFromSquare(this.file, this.rank)
      );
      event.dataTransfer.effectAllowed = "move";
    }

    event.stopPropagation();
    this.dragstart.emit(event);
  }
}

function calculateImageUrl(piece: Piece): string {
  const prefix = "assets/img/" + (piece.color === Color.Dark ? "d-" : "l-");
  const suffix = ".png";

  switch (piece.piece) {
    case PieceName.Pawn:
      return prefix + "pawn" + suffix;

    case PieceName.Rook:
      return prefix + "rook" + suffix;

    case PieceName.Knight:
      return prefix + "knight" + suffix;

    case PieceName.Bishop:
      return prefix + "bishop" + suffix;

    case PieceName.Queen:
      return prefix + "queen" + suffix;

    case PieceName.King:
      return prefix + "king" + suffix;

    default:
      return "";
  }
}

import { UciEngine, UciEngineFactory } from "./uci-registry.service";

export const stockfishFactory: UciEngineFactory = {
  id: "DecaGOZs6FLyExZXGvGs",
  name: "Stockfish 11",
  factory: () => new StockfishService(),
};

class StockfishService implements UciEngine {
  onCommand: ((command: string) => void) | null;
  stockfish: Worker;

  constructor() {
    this.onCommand = null;
    this.stockfish = new Worker("assets/stockfish/stockfish.js");

    this.stockfish.onmessage = (ev) => {
      if (this.onCommand !== null) {
        this.onCommand(ev.data);
      }
    };
  }

  sendCommand(command: string): void {
    this.stockfish.postMessage(command);
  }
}

import { BehaviorSubject, from, Observable, of } from "rxjs";
import { Injectable } from "@angular/core";

import firebase from "firebase/app";

import { FirebaseAuthService } from "./firebase-auth.service";
import { FirebaseStorageService } from "./firebase-storage.service";
import {
  FirestoreService,
  observableOnSnapshot,
  User,
} from "./firestore.service";
import { multicast, refCount, switchMap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  public user: Observable<firebase.User | null>;
  public profile: Observable<User | null>;

  constructor(
    private fireauth: FirebaseAuthService,
    private firestore: FirestoreService,
    private firebaseStorage: FirebaseStorageService
  ) {
    const onAuthStateChanged = new Observable<firebase.User | null>(
      (subscriber) => {
        const cleanup = this.fireauth.onAuthStateChanged((user) => {
          subscriber.next(user);
        });

        return cleanup;
      }
    );

    this.user = onAuthStateChanged.pipe(
      multicast(new BehaviorSubject(fireauth.currentUser)),
      refCount()
    );

    this.profile = this.user.pipe(
      switchMap((user) => {
        if (user === null) {
          return of(null);
        }

        return observableOnSnapshot(this.firestore.userDoc(user.uid));
      }),
      switchMap((snapshot) => {
        return from(this.updateProfile(snapshot?.data() ?? null));
      }),
      multicast(new BehaviorSubject(null as User | null)),
      refCount()
    );
  }

  private async updateProfile(user: User | null): Promise<User | null> {
    if (user === null) {
      return null;
    }

    // Fix the photoURL if it's been re-written by the cloud function
    if (user.photoURL !== null && user.photoURL.startsWith("users/avatars/")) {
      try {
        user.photoURL = (await this.firebaseStorage
          .ref(user.photoURL)
          .getDownloadURL()) as string;
        await this.firestore
          .userDoc(user.id)
          .set({ photoURL: user.photoURL }, { merge: true });
      } catch (err) {
        console.log("Error fixing photoURL", err);
        return user;
      }
    }

    // Update the displayName on the Firebase profile- make sure it's still the same user!
    const currentUser = this.fireauth.currentUser;
    if (currentUser?.uid === user.id) {
      let dirty = false;
      const profile: {
        displayName?: string | null;
        photoURL?: string | null;
      } = {};

      if (currentUser.displayName !== user.displayName) {
        dirty = true;
        profile.displayName = user.displayName;
      }

      if (currentUser.photoURL !== user.photoURL) {
        dirty = true;
        profile.photoURL = user.photoURL;
      }

      if (dirty) {
        try {
          await currentUser.updateProfile(profile);
        } catch (err) {
          console.warn("Error while updating user profile", err);
        }
      }
    }

    return user;
  }
}

<ng-container *ngIf="games$ | async; let games; else loading">
  <ng-container [ngSwitch]="games.status">
    <ng-container *ngSwitchCase="'loaded'">
      <ng-container *ngTemplateOutlet="gameGrid; context: games"></ng-container
    ></ng-container>
    <ng-container *ngSwitchCase="'error'"
      ><ng-container *ngTemplateOutlet="error; context: games"></ng-container
    ></ng-container>
  </ng-container>
</ng-container>
<ng-template #gameGrid let-games="games">
  <table mat-table [dataSource]="games">
    <ng-container matColumnDef="white">
      <th mat-header-cell *matHeaderCellDef>White</th>
      <td mat-cell *matCellDef="let game">
        <app-user [user]="game.white"></app-user>
      </td>
    </ng-container>

    <ng-container matColumnDef="black">
      <th mat-header-cell *matHeaderCellDef>Black</th>
      <td mat-cell *matCellDef="let game">
        <app-user [user]="game.black"></app-user>
      </td>
    </ng-container>

    <ng-container matColumnDef="result">
      <th mat-header-cell *matHeaderCellDef>Result</th>
      <td mat-cell *matCellDef="let game">{{game.result}}</td>
    </ng-container>

    <ng-container matColumnDef="site">
      <th mat-header-cell *matHeaderCellDef>Site</th>
      <td mat-cell *matCellDef="let game">
        {{game.site.length === 0 ? '-' : game.site}}
      </td>
    </ng-container>

    <ng-container matColumnDef="lastMove">
      <th mat-header-cell *matHeaderCellDef>Last Move</th>
      <td mat-cell *matCellDef="let game">{{game.lastMove}}</td>
    </ng-container>

    <ng-container matColumnDef="lastMoveDate">
      <th mat-header-cell *matHeaderCellDef>On</th>
      <td mat-cell *matCellDef="let game">
        {{game.lastMoveDate | date:'short'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="view">
      <th mat-header-cell *matHeaderCellDef>
        <button
          id="newGame"
          mat-stroked-button
          color="primary"
          [disabled]="(auth.user | async) === null"
          (click)="onNewGame()"
        >
          NEW
        </button>
      </th>
      <td mat-cell *matCellDef="let game">
        <button mat-stroked-button [routerLink]="'/game/' + game.id">
          VIEW
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
    <tr mat-row *matRowDef="let games; columns: displayColumns"></tr>
  </table>
</ng-template>
<ng-template #loading>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>
<ng-template #error let-error="error">
  <p>Error: {{error}}</p>
</ng-template>

<mat-toolbar color="primary">
  <span>TK Chess</span>
  <span class="toolbar-spacer"></span>
  <app-welcome-user></app-welcome-user>
</mat-toolbar>
<nav mat-tab-nav-bar [backgroundColor]="'primary'">
  <a
    mat-tab-link
    *ngFor="let tab of tabs"
    routerLink="{{tab.path}}"
    [active]="isActivePath(tab.path)"
  >
    {{tab.title}}
  </a>
</nav>

<router-outlet></router-outlet>

<mat-grid-list cols="42" rowHeight="1:1" gutterSize="0">
  <mat-grid-tile
    *ngFor="let tile of tiles"
    colspan="{{tile.colspan}}"
    rowspan="{{tile.rowspan}}"
    class="{{tile.className}}"
  >
    {{tile.contents}}
    <ng-container *ngIf="tile.file !== -1">
      <ng-container
        *ngTemplateOutlet="playingAs !== null ? activeTile : inactiveTile; context: {$implicit:tile}"
      ></ng-container
    ></ng-container>
  </mat-grid-tile>
</mat-grid-list>
<ng-template #activeTile let-tile>
  <div
    id="{{tile.square}}"
    [matBadge]="tile.attackers"
    [matBadgeHidden]="hideBadges"
    (click)="onClickSquare(tile.file, tile.rank)"
    (mouseenter)="onMouseEnter(tile.file, tile.rank)"
    (mouseleave)="onMouseLeave(tile.file, tile.rank)"
    (dragover)="onDragOver(tile.file, tile.rank, $event)"
    (dragenter)="onDragEnter(tile.file, tile.rank, $event)"
    (dragleave)="onDragLeave(tile.file, tile.rank, $event)"
    (drop)="onDrop(tile.file, tile.rank, $event)"
  >
    <app-game-piece
      *ngIf="tile.piece !== null"
      [piece]="tile.piece"
      size="large"
      [draggable]="tile.piece.color === turn"
      [file]="tile.file"
      [rank]="tile.rank"
      (click)="onClickPiece(tile.file, tile.rank)"
      (dragstart)="onDragStart(tile.file, tile.rank)"
    >
    </app-game-piece>
  </div>
</ng-template>
<ng-template #inactiveTile let-tile>
  <div
    id="{{tile.square}}"
    [matBadge]="tile.attackers"
    [matBadgeHidden]="hideBadges"
    (mouseenter)="onMouseEnter(tile.file, tile.rank)"
    (mouseleave)="onMouseLeave(tile.file, tile.rank)"
  >
    <app-game-piece
      *ngIf="tile.piece !== null"
      [piece]="tile.piece"
      size="large"
      [draggable]="false"
      [file]="tile.file"
      [rank]="tile.rank"
    >
    </app-game-piece>
  </div>
</ng-template>

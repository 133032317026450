import { Injectable } from "@angular/core";

import firebase from "firebase/app";
import "firebase/auth";

export type FirebaseAuthError = firebase.FirebaseError;
export type FirebaseUser = firebase.User;
export type FirebaseUnsubscribe = firebase.Unsubscribe;

@Injectable({
  providedIn: "root",
})
export class FirebaseAuthService {
  get currentUser(): firebase.User | null {
    return firebase.auth().currentUser;
  }

  onAuthStateChanged(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    callback: (user: firebase.User | null) => any
  ): firebase.Unsubscribe {
    return firebase.auth().onAuthStateChanged(callback);
  }

  signOut(): Promise<void> {
    return firebase.auth().signOut();
  }

  checkActionCode(code: string): Promise<firebase.auth.ActionCodeInfo> {
    return firebase.auth().checkActionCode(code);
  }

  confirmPasswordReset(code: string, newPassword: string): Promise<void> {
    return firebase.auth().confirmPasswordReset(code, newPassword);
  }

  signInWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<firebase.auth.UserCredential> {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  }

  createUserWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<firebase.auth.UserCredential> {
    return firebase.auth().createUserWithEmailAndPassword(email, password);
  }

  sendPasswordResetEmail(
    email: string,
    actionCodeSettings?: firebase.auth.ActionCodeSettings | null
  ): Promise<void> {
    return firebase.auth().sendPasswordResetEmail(email, actionCodeSettings);
  }
}

import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { MatDialog } from "@angular/material/dialog";

import { AuthenticationService } from "../authentication.service";
import { ChessServerService } from "../chess-server.service";
import { NewGameComponent } from "../new-game/new-game.component";
import { FirestoreError, Game } from "../firestore.service";
import { Observable, from, of } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  public games$!: Observable<
    | {
        status: "loaded";
        games: Game[];
      }
    | {
        status: "error";
        error: string;
      }
  >;
  public readonly displayColumns: string[];

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private chessServer: ChessServerService,
    public auth: AuthenticationService
  ) {
    this.displayColumns = [
      "view",
      "white",
      "black",
      "result",
      "site",
      "lastMove",
      "lastMoveDate",
    ];
  }

  ngOnInit(): void {
    this.games$ = from(this.chessServer.fetchGames()).pipe(
      map((games: Game[]) => {
        return {
          status: "loaded" as const,
          games: games,
        };
      }),
      catchError((err: FirestoreError) => {
        console.log("Error from fetchGames", err);
        return of({
          status: "error" as const,
          error: err.message,
        });
      })
    );
  }

  onNewGame(): void {
    const dialogRef = this.dialog.open(NewGameComponent, {
      width: "50em",
      height: "40em",
    });

    const subscription = dialogRef
      .afterClosed()
      .subscribe((gameId: string | undefined) => {
        if (gameId !== undefined) {
          void this.router.navigate(["/game", gameId]);
        }

        subscription.unsubscribe();
      });
  }
}

// Disabling due to the validator design
/* eslint-disable @typescript-eslint/unbound-method */

import { Component, Inject, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { Router } from "@angular/router";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";

import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";

import { FirestoreService, SERVER_NOW } from "../firestore.service";
import {
  FirebaseAuthError,
  FirebaseAuthService,
} from "../firebase-auth.service";

export interface AuthenticationDialogOptions {
  mode?: string;
  oobCode?: string;
}

function shouldBeUnreachable(): never {
  throw new Error("should never happen");
}

@Component({
  selector: "app-authentication-dialog",
  templateUrl: "./authentication-dialog.component.html",
  styleUrls: ["./authentication-dialog.component.scss"],
})
export class AuthenticationDialogComponent implements OnInit {
  mode: "signin" | "register" | "forgot password" | "password reset";
  form!: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<AuthenticationDialogComponent>,
    private firebaseAuth: FirebaseAuthService,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private router: Router,
    private location: Location,
    private firestore: FirestoreService,
    @Inject(MAT_DIALOG_DATA) private data: AuthenticationDialogOptions
  ) {
    this.mode = "signin";
  }

  ngOnInit(): void {
    if (
      typeof this.data.mode !== "undefined" ||
      typeof this.data.oobCode !== "undefined"
    ) {
      // REVIEW: Implement all of the operation codes:

      // `EMAIL_SIGNIN`: email sign in code generated via firebase.auth.Auth.sendSignInLinkToEmail.
      // `RECOVER_EMAIL`: email change revocation code generated via firebase.User.updateEmail.
      // `REVERT_SECOND_FACTOR_ADDITION`: revert second factor addition code generated via firebase.User.MultiFactorUser.enroll.
      // `VERIFY_AND_CHANGE_EMAIL`: verify and change email code generated via firebase.User.verifyBeforeUpdateEmail.
      // `VERIFY_EMAIL`: email verification code generated via firebase.User.sendEmailVerification.

      if (this.data.mode === "resetPassword") {
        // This is the firebase operation 'PASSWORD_RESET':
        this.mode = "password reset";
      }

      const subscription = this.dialogRef.afterClosed().subscribe((value) => {
        if (!value) {
          this.redirectToRemoveAuthQueryParameters();
        }

        subscription.unsubscribe();
      });
    }

    this.setMode(this.mode);
  }

  public setMode(
    mode: "signin" | "register" | "forgot password" | "password reset"
  ): void {
    this.mode = mode;

    let oldEmail = "";
    if (typeof this.form !== "undefined") {
      const formControl = this.form.get("email");
      if (formControl !== null) {
        oldEmail = formControl.value as string;
      }
    }

    switch (this.mode) {
      case "signin":
        this.form = this.fb.group({
          email: [oldEmail, [Validators.email, Validators.required]],
          password: ["", Validators.required],
        });
        break;

      case "register":
        this.form = this.fb.group(
          {
            email: [oldEmail, [Validators.email, Validators.required]],
            password: ["", [Validators.required, Validators.minLength(6)]],
            confirm: ["", Validators.required],
            displayName: [""],
            photoURL: [""],
            public: [true],
          },
          {
            validators: validateMatchingPasswords,
          }
        );
        break;

      case "forgot password":
        this.form = this.fb.group({
          email: [oldEmail, [Validators.email, Validators.required]],
        });
        break;

      case "password reset":
        this.form = this.fb.group(
          {
            email: [oldEmail],
            password: ["", [Validators.required, Validators.minLength(6)]],
            confirm: ["", Validators.required],
          },
          {
            validators: validateMatchingPasswords,
          }
        );
        void this.getEmailFromActionCode();
        break;
    }
  }

  title(): string {
    switch (this.mode) {
      case "signin":
        return "Sign in";

      case "register":
        return "Register a new account";

      case "forgot password":
        return "Forgot password";

      case "password reset":
        return "Reset password";
    }
  }

  fieldError(fieldName: string): boolean {
    return this.form.get(fieldName)?.invalid ?? false;
  }

  fieldErrorMessage(field: string): string {
    // REVIEW: Can .hasError() or .getError() make this code cleaner?

    const fieldControl = this.form.get(field);
    if (fieldControl === null) {
      // If this happens its a BUG! :)
      throw new Error("MISSING FIELD CONTROL: " + field);
    }

    const errors = fieldControl.errors as AuthenticationDialogFieldErrors;
    if (errors === null) {
      return ""; // No errors on this field.
    }

    if (errors.required) {
      return "This field is required.";
    }

    if (errors.email) {
      return "Please enter a valid email.";
    }

    if (errors.minlength) {
      return `Please enter at least ${errors.minlength.requiredLength} characters.`;
    }

    // Fallthrough for unexpected errors. Whenever this happens it is a bug (NYI).
    const keys = Object.keys(errors);
    if (keys.length > 0) {
      let found: string | null = null;
      for (const key of keys) {
        if (errors[key] === null) {
          continue;
        }

        if (found === null) {
          found = key;
          console.group("Unknown errors for field", field);
        }

        console.warn(key, errors[key]);
      }
      if (found !== null) {
        console.groupEnd();
      }

      return found ?? "";
    }

    return "";
  }

  formError(): boolean {
    return this.form.errors !== null && (this.form.touched || this.form.dirty);
  }

  formErrorMessage(): string {
    const errors = this.form.errors as AuthenticationDialogFormErrors;
    if (errors === null) {
      return "";
    }

    if (errors.signinFailed) {
      return "Invalid email or password.";
    }

    if (errors.registerFailed) {
      const code = errors.registerFailed.code;
      const msg = errors.registerFailed.message;

      switch (code) {
        case "auth/email-already-in-use":
        case "auth/invalid-email":
        case "auth/weak-password":
          return msg;

        default:
          // Generic registration error
          return "Invalid email or password.";
      }
    }

    if (errors.passwordResetEmailFailed) {
      const code = errors.passwordResetEmailFailed.code;
      const msg = errors.passwordResetEmailFailed.message;

      switch (code) {
        case "auth/invalid-email":
        case "auth/user-not-found":
          return msg;

        default:
          return "Unable to send password reset email, try again later.";
      }
    }

    if (errors.passwordResetFailed) {
      const code = errors.passwordResetFailed.code;
      const msg = errors.passwordResetFailed.message;

      switch (code) {
        case "auth/expired-action-code":
        case "auth/weak-password":
          return msg;

        default:
          return "Unable to reset password.";
      }
    }

    if (errors.passwordMismatch) {
      return "Passwords must match.";
    }

    // Fallthrough for unexpected errors. Whenever this happens it is a bug (NYI).
    const keys = Object.keys(errors);
    if (keys.length > 0) {
      let found: string | null = null;
      for (const key of keys) {
        if (errors[key] === null) {
          continue;
        }

        if (found === null) {
          found = key;
          console.group("Unknown errors for form");
        }

        console.warn(key, errors[key]);
      }
      if (found !== null) {
        console.groupEnd();
      }

      return found ?? "";
    }

    return "";
  }

  async ok(): Promise<void> {
    if (!this.form.valid) {
      this.snackBar.open("Please correct highlighted errors");
      return;
    }

    switch (this.mode) {
      case "signin":
        return this.performSignIn();

      case "register":
        return this.performRegister();

      case "forgot password":
        return this.performForgotPassword();

      case "password reset":
        return this.performPasswordReset();

      default:
        shouldBeUnreachable();
    }
  }

  private async performSignIn(): Promise<void> {
    try {
      await this.firebaseAuth.signInWithEmailAndPassword(
        this.form.get("email")?.value,
        this.form.get("password")?.value
      );

      this.dialogRef.close();
      this.snackBar.open("Sign in successful");
    } catch (err) {
      console.log("signInWithEmailAndPassword", err);
      this.form.get("password")?.setValue("");
      this.form.setErrors({ signinFailed: err as FirebaseAuthError });
    }
  }

  private async performRegister(): Promise<void> {
    const cleanupTasks: { msg: string; task: () => Promise<void> }[] = [];

    try {
      const email = this.form.get("email")?.value as string;
      const uc = await this.firebaseAuth.createUserWithEmailAndPassword(
        email,
        this.form.get("password")?.value
      );

      if (uc.user === null) {
        // It isn't clear to me reading the documentation if this ever happens or not.
        throw new Error("createUserWithEmailAndPassword failed");
      }
      const uid = uc.user.uid;

      // Add cleanup task.
      cleanupTasks.push({
        msg: "createUserWithEmailAndPassword",
        task: () => uc.user?.delete() ?? Promise.resolve(),
      });

      // Create the user doc in firestore
      await this.firestore.userDoc(uid).set(
        {
          active: true,
          displayName: this.form.get("displayName")?.value as string,
          joinDate: SERVER_NOW,
          photoURL: null,
          public: this.form.get("public")?.value as boolean,
          uci: null,
        },
        { merge: false }
      );

      // Add the cleanup task.
      cleanupTasks.push({
        msg: "userDoc.set",
        task: () => this.firestore.userDoc(uid).delete(),
      });

      // Create the user private doc in firestore
      await this.firestore.userPrivateDoc(uid).set(
        {
          providerId: "password",
          email: email,
          phoneNumber: null,
        },
        { merge: false }
      );
    } catch (err: unknown) {
      this.form.get("password")?.setValue("");
      this.form.get("confirm")?.setValue("");
      this.form.setErrors({ registerFailed: err });

      // run cleanup tasks
      console.log("Error registering new user", err);
      for (const cleanup of cleanupTasks) {
        console.log(cleanup.msg);
        await cleanup.task();
      }

      return;
    }

    this.dialogRef.close();
    this.snackBar.open("Registered");
  }

  private async performForgotPassword(): Promise<void> {
    const urlTree = this.router.createUrlTree(["/"], {
      queryParams: {
        email: this.form.get("email")?.value as string,
      },
    });
    const serialized = this.router.serializeUrl(urlTree);
    const prepared = this.location.prepareExternalUrl(serialized);
    const origin = window.location.origin;
    const url = origin + prepared;

    try {
      await this.firebaseAuth.sendPasswordResetEmail(
        this.form.get("email")?.value,
        { url }
      );

      this.dialogRef.close();
      this.snackBar.open("Password reset email sent. Check your inbox");
    } catch (err) {
      this.form.setErrors({
        passwordResetEmailFailed: err as FirebaseAuthError,
      });
    }
  }

  private async performPasswordReset(): Promise<void> {
    try {
      await this.firebaseAuth.confirmPasswordReset(
        this.data.oobCode ?? "",
        this.form.get("password")?.value
      );

      try {
        await this.firebaseAuth.signInWithEmailAndPassword(
          this.form.get("email")?.value,
          this.form.get("password")?.value
        );
      } catch (err) {
        // REVIEW: Log error
        console.log("signInWithEmailAndPassword", err);

        this.snackBar.open("Password changed - please sign in");
        this.setMode("signin");
        return;
      }

      const sub = this.snackBar
        .open("Password changed", "Close")
        .afterDismissed()
        .subscribe(() => {
          sub.unsubscribe();
          this.redirectToRemoveAuthQueryParameters();
        });

      this.dialogRef.close(true);
    } catch (err) {
      this.form.get("password")?.setValue("");
      this.form.get("confirm")?.setValue("");
      this.form.setErrors({ passwordResetFailed: err as FirebaseAuthError });
    }
  }

  private async getEmailFromActionCode(): Promise<void> {
    try {
      const info = await this.firebaseAuth.checkActionCode(
        this.data.oobCode ?? ""
      );

      this.form.get("email")?.setValue(info.data.email);
    } catch (err: unknown) {
      // REVIEW: Be more specific with the error? It is a FirebaseAuthError
      console.log("checkActionCode", err);

      this.setMode("forgot password");
      this.snackBar.open("Password reset email expired, please request again");
    }
  }

  private redirectToRemoveAuthQueryParameters(): void {
    const urlTree = this.router.parseUrl(this.router.url);
    urlTree.queryParams = {};

    const url = this.router.serializeUrl(urlTree);
    void this.router.navigateByUrl(url);
  }
}

const validateMatchingPasswords: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const password = control.get("password");
  const confirm = control.get("confirm");

  return password?.value !== confirm?.value ? { passwordMismatch: true } : null;
};

interface AuthenticationDialogFieldErrors extends ValidationErrors {
  required?: boolean | null;
  email?: boolean | null;
  minlength?: { requiredLength: number } | null;
}

interface AuthenticationDialogFormErrors extends ValidationErrors {
  passwordMismatch?: boolean | null;
  signinFailed?: FirebaseAuthError | null;
  registerFailed?: FirebaseAuthError | null;
  passwordResetEmailFailed?: FirebaseAuthError | null;
  passwordResetFailed?: FirebaseAuthError | null;
}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { Environment } from "./environment-interface";

export const environment: Environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyATFtcfZfQyOQszuV17ficGwxYmSLYM8ls",
    authDomain: "tk-chess.firebaseapp.com",
    projectId: "tk-chess",
    storageBucket: "tk-chess.appspot.com",
    messagingSenderId: "402590163118",
    appId: "1:402590163118:web:4eceb0b973e40289d10d3f",
    measurementId: "G-QF6FW89383",
  },
  loadDelay: 2500,
  uciLogging: true,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import "zone.js/dist/zone-error"; // Included with Angular CLI.import { Environment } from './environment-interface';

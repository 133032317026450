import { Component, Input } from "@angular/core";
import { Color } from "../game.service";

@Component({
  selector: "app-turn-indicator",
  templateUrl: "./turn-indicator.component.html",
  styleUrls: ["./turn-indicator.component.scss"],
})
export class TurnIndicatorComponent {
  @Input()
  result: string;

  @Input()
  turn: Color | null;

  @Input()
  check: boolean;

  @Input()
  player: Color | null;

  private _status: string;

  constructor() {
    this.result = "";
    this.turn = null;
    this.check = false;
    this.player = null;
    this._status = "";
  }

  get status(): string {
    let newStatus = "";

    if (this.result !== "*") {
      newStatus = "Final: " + this.result;
    } else {
      newStatus = "Turn: " + (this.turn === Color.Dark ? "Dark" : "Light");
      if (this.player !== null) {
        if (this.player === this.turn) {
          newStatus += " (you!)";
        } else {
          newStatus += " (opponent)";
        }
      }

      if (this.check) {
        newStatus += "; in check";
      }
    }

    if (newStatus !== this._status) {
      this._status = newStatus;
    }

    return this._status;
  }
}

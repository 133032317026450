/*eslint no-underscore-dangle: ["error", { "allowAfterThis": true }]*/

import { Inject, Injectable, InjectionToken } from "@angular/core";

import { UciService } from "./uci.service";
import { environment } from "../environments/environment";
import { FirestoreService, SERVER_NOW } from "./firestore.service";

export interface UciEngineId {
  id: string;
  name: string;
}

export const UCI_ENGINE_TOKEN = new InjectionToken<UciEngine>(
  "uci.engine.factory"
);

// REVIEW: This is a very weird pattern.
// Maybe make onCommand observable instead?
export interface UciEngine {
  onCommand: ((command: string) => void) | null;
  sendCommand(command: string): void;
}

export type UciEngineFactory = UciEngineId & { factory: () => UciEngine };

const logUciEngine = (uciEngine: UciEngine): UciEngine => {
  const wrapper: UciEngine = {
    sendCommand: (command: string) => {
      // REVIEW: How to log these?
      //console.info('UCI', 'GUI', command);
      uciEngine.sendCommand(command);
    },
    onCommand: null,
  };

  uciEngine.onCommand = (command: string) => {
    // REVIEW: How to log these?
    // console.info('UCI', 'Engine', command);
    if (wrapper.onCommand !== null) {
      wrapper.onCommand(command);
    }
  };

  return wrapper;
};

@Injectable({
  providedIn: "root",
})
export class UciRegistryService {
  private enginesCache: UciEngineId[];
  private enableLogging: boolean;

  constructor(
    @Inject(UCI_ENGINE_TOKEN) private _engines: Array<UciEngineFactory>,
    private firestore: FirestoreService
  ) {
    this.enginesCache = this._engines.map((engine) => ({
      id: engine.id,
      name: engine.name,
    }));
    this.enableLogging = environment.uciLogging;
  }

  get engines(): UciEngineId[] {
    return this.enginesCache;
  }

  createEngine(id: string): UciService {
    for (const engine of this._engines) {
      if (engine.id === id) {
        if (this.enableLogging) {
          return new UciService(logUciEngine(engine.factory()));
        } else {
          return new UciService(engine.factory());
        }
      }
    }

    throw new Error("Invalid engine ID: [" + id + "]");
  }

  // REVIEW: This needs to be called in some kind of admin context (cloud function?)
  public async createFirestoreUsers(): Promise<void> {
    for (const engine of this.engines) {
      // check in firestore to see if there is a user document for this engine
      try {
        const docRef = this.firestore.userDoc(engine.id);
        const doc = await docRef.get();
        if (!doc.exists) {
          await docRef.set(
            {
              active: true,
              displayName: engine.name,
              joinDate: SERVER_NOW,
              photoURL: null, // REVIEW: I should give engines an avatar!
              public: true,
              uci: engine.id,
            },
            { merge: false }
          );

          // REVIEW: This won't work once I fix the firestore rules!
          // need to do this in an admin script instead.
          await this.firestore.userPrivateDoc(engine.id).set(
            {
              providerId: "uci",
              email: null,
              phoneNumber: null,
            },
            { merge: false }
          );
        }
      } catch (err) {
        // REVIEW: Log this error!
        console.log(
          "Unable to register UCI engine in firestore",
          engine.id,
          err
        );
      }
    }
  }
}

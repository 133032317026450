export interface Square {
  file: number;
  rank: number;
}

export type OptionalSquare = Square | null;

export interface Piece {
  piece: "P" | "R" | "N" | "B" | "Q" | "K";
  dark: boolean;
}

export type OptionalPiece = Piece | null;

export interface Move {
  notation: string;
  game: Game;
  piece: string;
  fromFile: number;
  fromRank: number;
  toFile: number;
  toRank: number;
  capture: boolean;
  kingSideCastle: boolean;
  queenSideCastle: boolean;
  pawnPromotion: "P" | "R" | "N" | "B" | "Q" | "K" | null;
  check: boolean;
  checkmate: boolean;
  result: string | null;
}

export type Board = [
  [
    OptionalPiece,
    OptionalPiece,
    OptionalPiece,
    OptionalPiece,
    OptionalPiece,
    OptionalPiece,
    OptionalPiece,
    OptionalPiece
  ],
  [
    OptionalPiece,
    OptionalPiece,
    OptionalPiece,
    OptionalPiece,
    OptionalPiece,
    OptionalPiece,
    OptionalPiece,
    OptionalPiece
  ],
  [
    OptionalPiece,
    OptionalPiece,
    OptionalPiece,
    OptionalPiece,
    OptionalPiece,
    OptionalPiece,
    OptionalPiece,
    OptionalPiece
  ],
  [
    OptionalPiece,
    OptionalPiece,
    OptionalPiece,
    OptionalPiece,
    OptionalPiece,
    OptionalPiece,
    OptionalPiece,
    OptionalPiece
  ],
  [
    OptionalPiece,
    OptionalPiece,
    OptionalPiece,
    OptionalPiece,
    OptionalPiece,
    OptionalPiece,
    OptionalPiece,
    OptionalPiece
  ],
  [
    OptionalPiece,
    OptionalPiece,
    OptionalPiece,
    OptionalPiece,
    OptionalPiece,
    OptionalPiece,
    OptionalPiece,
    OptionalPiece
  ],
  [
    OptionalPiece,
    OptionalPiece,
    OptionalPiece,
    OptionalPiece,
    OptionalPiece,
    OptionalPiece,
    OptionalPiece,
    OptionalPiece
  ],
  [
    OptionalPiece,
    OptionalPiece,
    OptionalPiece,
    OptionalPiece,
    OptionalPiece,
    OptionalPiece,
    OptionalPiece,
    OptionalPiece
  ]
];

export interface Game {
  board: Board;
  history: Move[];
  captures: [Piece[], Piece[]]; // [light, dark]

  lastMoveValidation: [number, number, number, number, boolean] | undefined;
}

export function createGame(): Game {
  const board: Board = [
    [null, null, null, null, null, null, null, null],
    [null, null, null, null, null, null, null, null],
    [null, null, null, null, null, null, null, null],
    [null, null, null, null, null, null, null, null],
    [null, null, null, null, null, null, null, null],
    [null, null, null, null, null, null, null, null],
    [null, null, null, null, null, null, null, null],
    [null, null, null, null, null, null, null, null],
  ];

  const history: Move[] = [];

  const captures: [Piece[], Piece[]] = [[], []];

  const game = {
    board: board,
    history: history,
    captures: captures, // light, dark
    lastMoveValidation: undefined,
  };

  initializeBoard(game);
  return game;
}

export function createPiece(
  piece: "P" | "R" | "B" | "N" | "Q" | "K",
  dark: boolean
): Piece {
  return {
    piece: piece,
    dark: dark,
  };
}

export function createFileRank(file: number, rank: number): Square {
  return {
    file: file,
    rank: rank,
  };
}

export function stringFromFile(file: number): string {
  return String.fromCharCode("a".charCodeAt(0) + file);
}

export function createFileRankFromText(str: string): Square {
  return createFileRank(
    str.charCodeAt(0) - "a".charCodeAt(0),
    parseInt(str.substr(1)) - 1
  );
}

export function getSquareLabel(file: number, rank: number): string {
  return stringFromFile(file) + (rank + 1).toString();
}

export function isDarkTurn(game: Game): boolean {
  return game.history.length % 2 === 1;
}

function initializeBoard(game: Game): void {
  // pawns
  for (let file = 0; file < 8; file++) {
    game.board[file][1] = createPiece("P", false);
    game.board[file][6] = createPiece("P", true);
  }

  // rooks
  game.board[0][0] = createPiece("R", false);
  game.board[7][0] = createPiece("R", false);
  game.board[0][7] = createPiece("R", true);
  game.board[7][7] = createPiece("R", true);

  // knights
  game.board[1][0] = createPiece("N", false);
  game.board[6][0] = createPiece("N", false);
  game.board[1][7] = createPiece("N", true);
  game.board[6][7] = createPiece("N", true);

  // bishops
  game.board[2][0] = createPiece("B", false);
  game.board[5][0] = createPiece("B", false);
  game.board[2][7] = createPiece("B", true);
  game.board[5][7] = createPiece("B", true);

  // queens
  game.board[3][0] = createPiece("Q", false);
  game.board[3][7] = createPiece("Q", true);

  // kings
  game.board[4][0] = createPiece("K", false);
  game.board[4][7] = createPiece("K", true);
}

// Return the new file, rank after moving the given number of squares up and right
// The moveFile and moveRank may be negative
// Return null if the new square would be off the board, otherwise { file, rank }
function movePieceBy(
  file: number,
  rank: number,
  moveFile: number,
  moveRank: number
): OptionalSquare {
  file += moveFile;
  if (file < 0 || file > 7) {
    return null;
  }

  rank += moveRank;
  if (rank < 0 || rank > 7) {
    return null;
  }

  return createFileRank(file, rank);
}

// start from the square start
// for each iteration, move by moveFile, moveRank squares (may be negative)
// call fn(newSquare) for each resulting square. Stop when fn() returns true.
function movePieceUntil(
  file: number,
  rank: number,
  moveFile: number,
  moveRank: number,
  fn: (file: number, rank: number) => boolean
): void {
  do {
    const square = movePieceBy(file, rank, moveFile, moveRank);
    if (square === null) {
      // moved off the board
      break;
    }

    file = square.file;
    rank = square.rank;
  } while (!fn(file, rank));
}

export function computeMovesForSquare(
  game: Game,
  file: number,
  rank: number,
  strict: boolean = true
): Square[] {
  return computeMovesForSquareEx(game, file, rank, strict, false);
}

// strict: only return moves that can really be made (e.g. don't include same color pieces, etc.)
// simple: skip test for check (to avoid infinite recursion)
function computeMovesForSquareEx(
  game: Game,
  file: number,
  rank: number,
  strict: boolean,
  simple: boolean
): Square[] {
  const p = game.board[file][rank];
  if (p === null) {
    return []; // no piece, no moves!
  }

  const piece: Piece = p;

  let moves: Square[] = [];
  switch (piece.piece) {
    case "P":
      moves = computeMovesForPawn(game, file, rank, piece.dark, strict);
      break;

    case "N":
      moves = computeMovesForKnight(game, file, rank, piece.dark, strict);
      break;
  }

  if ("RBQK".indexOf(piece.piece) !== -1) {
    // Rook/Bishop/Queen/King*, the pieces with "simple" movement (castling handled later).
    const fn = function (file: number, rank: number): boolean {
      return appendSquare(
        game.board,
        strict,
        piece.dark,
        piece.piece === "K",
        moves,
        file,
        rank
      );
    };

    if (piece.piece !== "B") {
      // Rook/Queen/King can move in a plus
      movePieceUntil(file, rank, 1, 0, fn);
      movePieceUntil(file, rank, -1, 0, fn);
      movePieceUntil(file, rank, 0, 1, fn);
      movePieceUntil(file, rank, 0, -1, fn);
    }

    if (piece.piece !== "R") {
      // Bishop/Queen/King can move on the diagonal
      movePieceUntil(file, rank, -1, 1, fn);
      movePieceUntil(file, rank, 1, 1, fn);
      movePieceUntil(file, rank, -1, -1, fn);
      movePieceUntil(file, rank, 1, -1, fn);
    }

    // castling
    if (!simple && piece.piece === "K" && !isKingInCheck(game, piece.dark)) {
      // check king side
      if (
        checkHistoryForCastling(game, piece.dark, true) &&
        game.board[5][rank] === null &&
        game.board[6][rank] === null &&
        getSquaresAttackingSquare(game, 5, rank, piece.dark).length === 0 &&
        getSquaresAttackingSquare(game, 6, rank, piece.dark).length === 0
      ) {
        moves.push(createFileRank(6, rank));
      }

      // check queen side
      if (
        checkHistoryForCastling(game, piece.dark, false) &&
        game.board[3][rank] === null &&
        game.board[2][rank] === null &&
        game.board[1][rank] === null &&
        getSquaresAttackingSquare(game, 3, rank, piece.dark).length === 0 &&
        getSquaresAttackingSquare(game, 2, rank, piece.dark).length === 0 &&
        getSquaresAttackingSquare(game, 1, rank, piece.dark).length === 0
      ) {
        moves.push(createFileRank(2, rank));
      }
    }
  }

  if (!simple && moves !== null) {
    // REVIEW: Move this into a seperate function
    // Make a duplicate game to use for testing move validity
    const history = game.history;
    game.history = []; // avoid exponential game growth!
    const gameBackup = JSON.stringify(game);
    game.history = history;
    const historyBackup = JSON.stringify(history);

    let i = 0;
    while (i < moves.length && piece !== null) {
      const g2 = JSON.parse(gameBackup) as Game;
      g2.history = JSON.parse(historyBackup) as Move[];

      // moves will include some for which the destination is the same color- skip these!
      const target = g2.board[moves[i].file][moves[i].rank];
      if (target !== null && target.dark === g2.board[file][rank]?.dark) {
        i += 1;
        continue;
      }

      // make the move
      g2.board[moves[i].file][moves[i].rank] = g2.board[file][rank];
      g2.board[file][rank] = null;

      // push the move into the history
      g2.history.push(
        createHistoryObject(
          g2,
          piece.piece,
          file,
          rank,
          moves[i].file,
          moves[i].rank
        )
      );

      // check if our own king is in check
      if (isKingInCheck(g2, piece.dark)) {
        moves.splice(i, 1); // remove this move, it is invalid
      } else {
        i += 1;
      }
    }
  }

  return moves !== null ? moves : [];
}

function computeMovesForPawn(
  game: Game,
  file: number,
  rank: number,
  dark: boolean,
  strict: boolean
): Square[] {
  const moves: Square[] = [];

  // set the direction of movement
  const forwardRank = dark ? -1 : 1;

  // attack forward 1 square and over 1 square- the normal capture for a pawn
  for (let i = 0; i < 2; i++) {
    // we are using a loop since it's duplicated code
    const newFile = i === 0 ? -1 : 1;
    const square = movePieceBy(file, rank, newFile, forwardRank);
    if (square !== null) {
      const piece = game.board[square.file][square.rank];
      if (!strict || (piece !== null && piece.dark !== dark)) {
        moves.push(square);
      }
    }
  }

  // En passant capture?
  if (rank === (dark ? 3 : 4)) {
    if (game.history.length > 0) {
      const lastMove = game.history[game.history.length - 1];

      // was the last move a double pawn move in an adjacent file?
      if (
        lastMove.piece === "P" &&
        lastMove.fromFile === lastMove.toFile &&
        lastMove.fromRank === (dark ? 1 : 6) &&
        lastMove.toRank === (dark ? 3 : 4) &&
        (lastMove.fromFile === file + 1 || lastMove.fromFile === file - 1)
      ) {
        // eligibile for en passant capture
        moves.push(createFileRank(lastMove.fromFile, rank + forwardRank));
      }
    }
  }

  // one or two squares forward (if they are empty)
  let forward = movePieceBy(file, rank, 0, forwardRank);
  if (forward !== null && game.board[forward.file][forward.rank] === null) {
    moves.push(createFileRank(forward.file, forward.rank));

    // if the pawn hasn't moved yet we may move by two
    if ((dark && rank === 6) || (!dark && rank === 1)) {
      forward = movePieceBy(file, rank, 0, forwardRank * 2);
      if (forward !== null && game.board[forward.file][forward.rank] === null) {
        moves.push(createFileRank(forward.file, forward.rank));
      }
    }
  }

  return moves;
}

function appendSquare(
  board: Board,
  checkColor: boolean,
  dark: boolean,
  once: boolean,
  moves: Square[],
  file: number,
  rank: number
) {
  const piece = board[file][rank];
  if (piece === null) {
    moves.push(createFileRank(file, rank));
  } else {
    if (!checkColor || piece.dark !== dark) {
      moves.push(createFileRank(file, rank));
    }

    return true;
  }

  return once;
}

function computeMovesForKnight(
  game: Game,
  file: number,
  rank: number,
  dark: boolean,
  strict: boolean
): Square[] {
  // knight
  const moves: Square[] = [];
  const fn = function (file: number, rank: number): boolean {
    return appendSquare(game.board, strict, dark, true, moves, file, rank);
  };

  // up 2, left 1
  movePieceUntil(file, rank, -1, 2, fn);

  // up 2, right 1
  movePieceUntil(file, rank, 1, 2, fn);

  // down 2, left 1
  movePieceUntil(file, rank, -1, -2, fn);

  // down 2, right 1
  movePieceUntil(file, rank, 1, -2, fn);

  // up 1, left 2
  movePieceUntil(file, rank, -2, 1, fn);

  // up 1, right 2
  movePieceUntil(file, rank, 2, 1, fn);

  // down 1, left 2
  movePieceUntil(file, rank, -2, -1, fn);

  // down 1, right 2
  movePieceUntil(file, rank, 2, -1, fn);

  return moves;
}

function checkHistoryForCastling(
  game: Game,
  dark: boolean,
  kingSide: boolean
): boolean {
  for (let i = dark ? 1 : 0; i < game.history.length; i += 2) {
    if (
      game.history[i].piece === "K" ||
      game.history[i].kingSideCastle ||
      game.history[i].queenSideCastle
    ) {
      return false;
    }

    if (
      game.history[i].piece === "R" &&
      game.history[i].fromFile === (kingSide ? 7 : 0) &&
      game.history[i].fromRank === (dark ? 7 : 0)
    ) {
      return false;
    }
  }

  return true;
}

export function getSquaresAttackingSquare(
  game: Game,
  file: number,
  rank: number,
  dark: boolean
): Square[] {
  const attackers: Square[] = [];

  for (let attackingFile = 0; attackingFile < 8; attackingFile++) {
    for (let attackingRank = 0; attackingRank < 8; attackingRank++) {
      const piece = game.board[attackingFile][attackingRank];
      if (piece !== null && piece.dark !== dark) {
        const moves = computeMovesForSquareEx(
          game,
          attackingFile,
          attackingRank,
          false,
          true
        );

        for (let i = 0; i < moves.length; i++) {
          // REVIEW: This is kind of a weird place to remove the pawn forward moves
          if (
            moves[i].file === file &&
            moves[i].rank === rank &&
            (piece.piece !== "P" || attackingFile !== file)
          ) {
            attackers.push(createFileRank(attackingFile, attackingRank));
            break;
          }
        }
      }
    }
  }

  return attackers;
}

function createHistoryObject(
  game: Game,
  piece: string,
  fromFile: number,
  fromRank: number,
  toFile: number,
  toRank: number
): Move {
  const history = game.history;
  game.history = []; // avoid exponential game growth!

  const move = {
    game: JSON.parse(JSON.stringify(game)) as Game,
    piece: piece,
    fromFile: fromFile,
    fromRank: fromRank,
    toFile: toFile,
    toRank: toRank,
    capture: false,
    kingSideCastle: false,
    queenSideCastle: false,
    pawnPromotion: null,
    check: false,
    checkmate: false,
    result: null,
    notation: "",
  };

  game.history = history;

  return move;
}

function setNotation(move: Move): void {
  let notation = "";

  if (move.kingSideCastle) {
    notation = "O-O";
  } else if (move.queenSideCastle) {
    notation = "O-O-O";
  } else {
    // "Normal" move
    if (move.piece !== "P") {
      notation = move.piece;
    }

    if (move.capture && move.piece === "P") {
      notation += stringFromFile(move.fromFile);
    } else {
      // Is disambiguation required?
      // Use the board from pre-move, and see if the there is another
      // piece that could make the same move.

      const similarPieces: Square[] = [];

      for (let file = 0; file < 8; file++) {
        for (let rank = 0; rank < 8; rank++) {
          const piece = move.game.board[file][rank];
          if (piece === null) {
            continue;
          }

          if (move.piece !== piece.piece) {
            continue;
          }

          if (isDarkTurn(move.game) !== piece.dark) {
            continue;
          }

          const moves = computeMovesForSquareEx(
            move.game,
            file,
            rank,
            true,
            true
          );
          for (let i = 0; i < moves.length; i++) {
            if (
              moves[i].file === move.toFile &&
              moves[i].rank === move.toRank
            ) {
              similarPieces.push(moves[i]);
            }
          }
        }
      }

      // now we have all the similar pieces, determine the correct disambiguation
      if (similarPieces.length > 1) {
        let countOfMatchingFile = 0;
        let countOfMatchingRank = 0;

        for (let i = 0; i < similarPieces.length; i++) {
          if (similarPieces[i].file === move.fromFile) {
            countOfMatchingFile += 1;
          }

          if (similarPieces[i].rank === move.fromRank) {
            countOfMatchingRank += 1;
          }
        }

        if (countOfMatchingFile === 1) {
          notation += stringFromFile(move.fromFile);
        } else if (countOfMatchingRank === 1) {
          notation += move.fromRank + 1;
        } else {
          notation +=
            stringFromFile(move.fromFile) + (move.fromRank + 1).toString();
        }
      }
    }

    notation +=
      (move.capture ? "x" : "") + getSquareLabel(move.toFile, move.toRank);

    if (move.pawnPromotion !== null) {
      notation += "=" + move.pawnPromotion;
    }

    if (move.checkmate) {
      notation += "#";
    } else if (move.check) {
      notation += "+";
    }
  }

  move.notation = notation;
}

function findKing(board: Board, dark: boolean): Square {
  for (let file = 0; file < 8; file++) {
    for (let rank = 0; rank < 8; rank++) {
      const piece = board[file][rank];
      if (piece !== null) {
        if (piece.piece === "K" && piece.dark === dark) {
          return createFileRank(file, rank);
        }
      }
    }
  }

  throw "This should never happen!"; // REVIEW: Better exception type?
}

// Return true if the dark king is under attack.
function isKingInCheck(game: Game, dark: boolean): boolean {
  const king = findKing(game.board, dark);

  // who is attacking that square?
  const attackers = getSquaresAttackingSquare(game, king.file, king.rank, dark);
  return attackers.length > 0;
}

// Look at the board and see if the given side (dark) is
// in check, checkmate, or stalemate, updating the move
// object accordingly.
function detectCheckEtc(game: Game, move: Move, darkTurn: boolean): void {
  // Check? Look for any pieces attacking the king
  const check = isKingInCheck(game, !darkTurn);

  // Computing stalemate and checkmate is very similar.
  // In the case of checkmate, it means the opponents
  // king is in check, and there is no legal move to
  // escape check. In the case of stalement, it means
  // the opponent has no legal moves, but is not in check.
  let stalemate = check ? false : true;
  let checkmate = check ? true : false;

  // Make a duplicate game to use for the test moves- this is so we
  // don't risk corrupting the real game.

  const history = game.history;
  game.history = []; // avoid exponential game growth!
  const gameBackup = JSON.stringify(game);
  game.history = history;
  let g2 = JSON.parse(gameBackup) as Game;

  // Add the move to history to compute moves.
  history.push(move);

  const historyBackup = JSON.stringify(history);
  g2.history = JSON.parse(historyBackup) as Move[];

  history.pop();

  for (let file = 0; file < 8; file++) {
    for (let rank = 0; rank < 8; rank++) {
      const piece = g2.board[file][rank];
      if (piece !== null && piece.dark !== darkTurn) {
        const moves = computeMovesForSquare(g2, file, rank);
        if (stalemate && moves.length > 0) {
          // We only need to find one legal move to avoid stalemate.
          stalemate = false;
          break;
        } else if (checkmate) {
          for (let i = 0; i < moves.length; i++) {
            movePiece(g2, file, rank, moves[i].file, moves[i].rank);
            if (!isKingInCheck(g2, !darkTurn)) {
              console.log(
                `To break check, move ${getSquareLabel(
                  file,
                  rank
                )} to ${getSquareLabel(moves[i].file, moves[i].rank)}`
              );
              checkmate = false;
              break;
            } else {
              // reset the game board so we can try the next move
              g2 = JSON.parse(gameBackup) as Game;
              g2.history = JSON.parse(historyBackup) as Move[];
            }
          }
        }
      }
    }
  }

  if (checkmate) {
    move.checkmate = true;
    move.result = darkTurn ? "0-1" : "1-0";
  } else if (check) {
    move.check = true;
  } else if (stalemate) {
    move.result = "1/2-1/2";
  }
}

export function movePiece(
  game: Game,
  fromFile: number,
  fromRank: number,
  toFile: number,
  toRank: number,
  validateOnly?: boolean
): boolean {
  if (validateOnly && typeof game.lastMoveValidation !== "undefined") {
    if (
      game.lastMoveValidation[0] === fromFile &&
      game.lastMoveValidation[1] === fromRank &&
      game.lastMoveValidation[2] === toFile &&
      game.lastMoveValidation[3] === toRank
    ) {
      return game.lastMoveValidation[4];
    }
  }

  game.lastMoveValidation = undefined;

  const darkTurn = isDarkTurn(game);

  const fromPiece = game.board[fromFile][fromRank];
  if (fromPiece === null) {
    return false;
  }

  // move object for history
  const move = createHistoryObject(
    game,
    fromPiece.piece,
    fromFile,
    fromRank,
    toFile,
    toRank
  );

  // Check that the move requested is valid!
  const validMoves = computeMovesForSquare(game, fromFile, fromRank);
  let found = false;
  for (let i = 0; i < validMoves.length; i++) {
    if (validMoves[i].file === toFile && validMoves[i].rank === toRank) {
      found = true;
      break;
    }
  }

  if (!found) {
    return false;
  }

  if (validateOnly) {
    game.lastMoveValidation = [fromFile, fromRank, toFile, toRank, found];

    return found;
  }

  let capturedPiece = game.board[toFile][toRank];

  // Pawn capture en passant
  if (capturedPiece === null && move.piece === "P" && fromFile !== toFile) {
    capturedPiece = game.board[toFile][fromRank];
    game.board[toFile][fromRank] = null;
  }

  if (capturedPiece !== null) {
    // Handle a capture

    // record the captured piece
    game.captures[darkTurn ? 1 : 0].push(capturedPiece);

    // move the piece
    game.board[toFile][toRank] = game.board[fromFile][fromRank];
    game.board[fromFile][fromRank] = null;

    // mark it as a capture in the history object
    move.capture = true;
  } else if (
    move.piece === "K" &&
    toRank === fromRank &&
    fromFile === 4 &&
    (toFile === 2 || toFile === 6)
  ) {
    // castling

    // has the king/rook moved before?
    const kingSide = toFile === 6;
    if (checkHistoryForCastling(game, darkTurn, kingSide)) {
      // move the king
      game.board[toFile][toRank] = game.board[fromFile][fromRank];
      game.board[fromFile][fromRank] = null;

      if (kingSide) {
        // record the king side castling
        move.kingSideCastle = true;

        // move the rook
        game.board[5][toRank] = game.board[7][toRank];
        game.board[7][toRank] = null;
      } else {
        // record the queen side castling
        move.queenSideCastle = true;

        // move the rook
        game.board[3][toRank] = game.board[0][toRank];
        game.board[0][toRank] = null;
      }
    } else {
      return false;
    }
  } else {
    // it's a normal, simple move
    game.board[toFile][toRank] = game.board[fromFile][fromRank];
    game.board[fromFile][fromRank] = null;
  }

  // Pawn promotion
  if (move.piece === "P" && move.toRank === (darkTurn ? 0 : 7)) {
    // REVIEW: Hard coding queen for now. Should have some UI for this later
    move.pawnPromotion = "Q";
    game.board[toFile][toRank] = createPiece(move.pawnPromotion, darkTurn);
  }

  // look for check and checkmate, updating the move object if found
  detectCheckEtc(game, move, darkTurn);

  // compute the chess notation and record the move in history
  // temporarily set the history in the game attached to the move
  move.game.history = game.history;
  setNotation(move);
  move.game.history = [];
  game.history.push(move);

  return true;
}

<h1 mat-dialog-title>New Game</h1>
<mat-dialog-content>
  <mat-vertical-stepper
    [linear]="true"
    #stepper
    (selectionChange)="onStepperSelectionChange($event)"
  >
    <mat-step #stepGameTypeStep label="Type" [completed]="gameTypeCompleted">
      <div class="form playAs">
        <label>Play as: </label>
        <mat-radio-group [(ngModel)]="playAs" aria-label="Play as">
          <mat-radio-button value="white">White</mat-radio-button>
          <mat-radio-button value="black">Black</mat-radio-button>
          <mat-radio-button value="random">Random</mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="form gameTypes">
        <div class="gameType">
          <button
            mat-fab
            [color]="computerGame == true ? 'primary' : 'accent'"
            matTooltip="Play against computer"
            class="gameTypeButton"
            aria-label="Play against computer"
            [disabled]="playAs === undefined"
            (click)="onClickGameType(true)"
          >
            <mat-icon>computer</mat-icon>
          </button>
        </div>
        <mat-divider [vertical]="true" class="divider"></mat-divider>
        <div class="gameType">
          <button
            mat-fab
            [color]="computerGame == false ? 'primary' : 'accent'"
            matTooltip="Play against another human"
            class="gameTypeButton"
            aria-label="Play against another human"
            [disabled]="playAs === undefined"
            (click)="onClickGameType(false)"
          >
            <mat-icon>person</mat-icon>
          </button>
        </div>
      </div>
    </mat-step>

    <mat-step label="Tags" [stepControl]="gameInfoForm">
      <form [formGroup]="gameInfoForm" id="gameInfoForm" class="form">
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Event</mat-label>
          <input
            matInput
            type="text"
            formControlName="event"
            placeholder="Event"
          />
          <mat-hint>Name of the tournament or match event.</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Site</mat-label>
          <input
            matInput
            type="text"
            formControlName="site"
            placeholder="Site"
          />
          <mat-hint
            >Location of the event (e.g. New York City, NY USA.).</mat-hint
          >
        </mat-form-field>

        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Round</mat-label>
          <input
            matInput
            type="text"
            formControlName="round"
            placeholder="Round"
          />
          <mat-hint
            >Playing round ordinal of the game within the event.</mat-hint
          >
        </mat-form-field>
        <!-- Use a Material Expansion panel for more tags -->
      </form>
      <div class="stepper-actions">
        <button mat-stroked-button matStepperPrevious>BACK</button>
        <button mat-stroked-button matStepperNext>NEXT</button>
      </div>
    </mat-step>

    <mat-step *ngIf="computerGame" label="Engine" [completed]="engineCompleted">
      <form [formGroup]="engineForm" id="engineForm" class="form">
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Chess engine</mat-label>
          <mat-select
            formControlName="engine"
            (selectionChange)="updateEngineOptions($event.value)"
          >
            <mat-option
              *ngFor="let engine of uciRegistry.engines"
              [value]="engine.id"
            >
              {{engine.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-expansion-panel [disabled]="engineOptions.length === 0">
          <mat-expansion-panel-header>
            <mat-panel-title> Engine options </mat-panel-title>
            <mat-panel-description>
              Set options used by the selected chess engine.
            </mat-panel-description>
          </mat-expansion-panel-header>

          <ng-container
            *ngFor="let option of engineOptions; let i=index"
            formArrayName="engineOptions"
          >
            <ng-container [ngSwitch]="option.type">
              <div *ngSwitchCase="'check'">
                <mat-slide-toggle [formControlName]="i"
                  >{{option.name}}</mat-slide-toggle
                >
              </div>

              <mat-form-field
                *ngSwitchCase="'combo'"
                appearance="fill"
                class="full-width"
              >
                <mat-label>{{option.name}}</mat-label>
                <mat-select [formControlName]="i">
                  <mat-option
                    *ngFor="let possible of option.var"
                    [value]="possible"
                  >
                    {{possible}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <div *ngSwitchCase="'button'">
                <button mat-button (click)="onClickOption(option.name)">
                  {{option.name}}
                </button>
              </div>

              <mat-form-field
                *ngSwitchDefault
                appearance="fill"
                class="full-width"
              >
                <mat-label>{{option.name}}</mat-label>
                <input
                  matInput
                  type="{{inputType(option.type)}}"
                  [formControlName]="i"
                  min="{{inputMin(option.type, option.min)}}"
                  max="{{inputMin(option.type, option.max)}}"
                />
              </mat-form-field>
            </ng-container>
          </ng-container>
        </mat-expansion-panel>
      </form>
      <div class="stepper-actions">
        <button mat-stroked-button matStepperPrevious>BACK</button>
        <button
          mat-stroked-button
          matStepperNext
          [disabled]="!engineCompleted"
          (click)="onStart()"
        >
          START
        </button>
      </div>
    </mat-step>

    <mat-step
      *ngIf=" !computerGame"
      #opponentStep
      label="Opponent"
      [completed]="opponentCompleted"
    >
      <form [formGroup]="opponentForm" id="opponentForm" class="form">
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Game Invitation Code</mat-label>
          <input matInput type="text" readonly formControlName="invitation" />
          <mat-hint>Share this invite code with your friend.</mat-hint>
        </mat-form-field>
        <p>Join URL: <span>{{joinUrl}}</span></p>
        <p id="message">{{opponentMessage}}</p>
      </form>
      <div class="stepper-actions">
        <button mat-stroked-button matStepperPrevious>BACK</button>
        <button
          mat-stroked-button
          matStepperNext
          [disabled]="!opponentCompleted"
          (click)="onStart()"
        >
          START
        </button>
      </div>
    </mat-step>
  </mat-vertical-stepper>
</mat-dialog-content>

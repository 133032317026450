import { Injectable } from "@angular/core";

import {
  Environment,
  FirebaseInfo,
} from "../environments/environment-interface";
import { environment } from "../environments/environment";

class EnvironmentFirebaseInfo implements FirebaseInfo {
  get apiKey(): string {
    return environment.firebase.apiKey;
  }

  get authDomain(): string {
    return environment.firebase.authDomain;
  }

  get projectId(): string {
    return environment.firebase.projectId;
  }

  get storageBucket(): string {
    return environment.firebase.storageBucket;
  }

  get messagingSenderId(): string {
    return environment.firebase.messagingSenderId;
  }

  get appId(): string {
    return environment.firebase.appId;
  }

  get measurementId(): string {
    return environment.firebase.measurementId;
  }
}

@Injectable({
  providedIn: "root",
})
export class EnvironmentService implements Environment {
  // The goal of this service is to be able to use DI to seperate
  // environment for testing.
  private environmentFirebaseInfo: EnvironmentFirebaseInfo;

  constructor() {
    this.environmentFirebaseInfo = new EnvironmentFirebaseInfo();
  }

  get production(): boolean {
    return environment.production;
  }

  get loadDelay(): number {
    return environment.loadDelay;
  }

  get uciLogging(): boolean {
    return environment.uciLogging;
  }

  get firebase(): FirebaseInfo {
    return this.environmentFirebaseInfo;
  }
}

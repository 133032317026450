import { UciEngine } from "./uci-registry.service";

// REVIEW: Readonly<T> may help here!

export type UciEngineOptionType =
  | "check"
  | "spin"
  | "combo"
  | "button"
  | "string"
  | undefined;

export interface UciEngineOption {
  readonly name: string;
  readonly type: UciEngineOptionType;
  readonly default: boolean | number | string | undefined;
  readonly min: number | undefined;
  readonly max: number | undefined;
  readonly var: string[] | undefined;
}

export interface UciEngineInfo {
  readonly name: string;
  readonly author: string;
  readonly options: UciEngineOption[];
}

export interface UciGoOptions {
  // * searchmoves < move1 > ....<movei>
  //   restrict search to this moves only
  //   Example: After "position startpos" and "go infinite searchmoves e2e4 d2d4"
  //   the engine should only search the two moves e2e4 and d2d4 in the initial position.
  readonly searchmoves?: string[];

  // * ponder
  //   start searching in pondering mode.
  //   Do not exit the search in ponder mode, even if it's mate!
  //   This means that the last move sent in in the position string is the ponder move.
  //   The engine can do what it wants to do, but after a "ponderhit" command
  //   it should execute the suggested move to ponder on.This means that the ponder move sent by
  //   the GUI can be interpreted as a recommendation about which move to ponder.However, if the
  // 	 engine decides to ponder on a different move, it should not display any mainlines as they are
  //   likely to be misinterpreted by the GUI because the GUI expects the engine to ponder
  //   on the suggested move.
  readonly ponder?: boolean;

  // * wtime < x >
  //   white has x msec left on the clock
  readonly wtime?: number;

  // * btime < x >
  //   black has x msec left on the clock
  readonly btime?: number;

  // * winc < x >
  //   white increment per move in mseconds if x > 0
  readonly winc?: number;

  // * binc < x >
  //   black increment per move in mseconds if x > 0
  readonly binc?: number;

  // * movestogo < x >
  //   there are x moves to the next time control,
  //   this will only be sent if x > 0,
  // 	 if you don't get this and get the wtime and btime it's sudden death
  readonly movestogo?: number;

  // * depth < x >
  //   search x plies only.
  readonly depth?: number;

  // * nodes < x >
  //   search x nodes only,
  readonly nodes?: number;

  // * mate < x >
  //   search for a mate in x moves
  readonly mate?: number;

  // * movetime < x >
  //   search exactly x mseconds
  readonly movetime?: number;

  // * infinite
  //   search until the "stop" command.Do not exit the search without being told so in this mode!
  readonly infinite?: boolean;
}

// REVIEW: What about OnDestory class inheritance?
// REVIEW: Not yet implemented: copyprotection commands, registration commands
export class UciService {
  private pendingCommands: Array<string>;
  private pendingCommandResolve: ((command: string) => void) | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private pendingCommandReject: ((err: any) => void) | null;

  constructor(private engine: UciEngine) {
    this.pendingCommands = [];
    this.pendingCommandResolve = null;
    this.pendingCommandReject = null;

    // REVIEW: Is there a more elegant way to handle the this problem?
    engine.onCommand = (command) => {
      this.onEngineCommand(command);
    };
  }

  onDestroy(): void {
    this.engine.sendCommand("quit\n");
  }

  async uci(): Promise<UciEngineInfo> {
    const ret: { name: string; author: string; options: UciEngineOption[] } = {
      options: [],
      name: "",
      author: "",
    };

    this.processPendingEngineCommands();

    this.engine.sendCommand("uci\n");
    for (;;) {
      const command = await this.getNextEngineCommand();

      let [token, remainder] = nextToken(command);
      if (token === "uciok") {
        // final response to the 'uci' command.
        break;
      }

      switch (token) {
        case "id":
          [token, remainder] = nextToken(remainder);
          if (token === "name") {
            ret.name = remainder;
          } else if (token === "author") {
            ret.author = remainder;
          } else {
            console.log("UCI", "Unknown id command", remainder);
          }
          break;

        case "option": {
          const option: {
            name: string;
            type: "check" | "spin" | "combo" | "button" | "string" | undefined;
            default: boolean | number | string | undefined;
            min: number | undefined;
            max: number | undefined;
            var: string[] | undefined;
          } = {
            name: "",
            type: "string",
            default: undefined,
            min: undefined,
            max: undefined,
            var: undefined,
          };

          let unknownTokenDestination:
            | "name"
            | "default"
            | "min"
            | "max"
            | "var"
            | null = null;
          while (remainder.length > 0) {
            [token, remainder] = nextToken(remainder);
            if (token === "name") {
              option.name = "";
              unknownTokenDestination = "name";
            } else if (token === "type") {
              unknownTokenDestination = null;
              [token, remainder] = nextToken(remainder);
              switch (token) {
                case "check":
                case "spin":
                case "combo":
                case "button":
                case "string":
                  option.type = token;
                  break;

                default:
                  console.log("UCI", "Unknown type", token);
                  option.type = undefined;
              }
            } else if (token === "default") {
              option.default = "";
              unknownTokenDestination = "default";
            } else if (token === "min") {
              option.min = 0;
              unknownTokenDestination = "min";
            } else if (token === "max") {
              option.max = 0;
              unknownTokenDestination = "max";
            } else if (token === "var") {
              if (typeof option.var === "undefined") {
                option.var = [];
              }
              option.var.push("");
              unknownTokenDestination = "var";
            } else if (unknownTokenDestination !== null) {
              switch (unknownTokenDestination) {
                case "var":
                  if (option.var === undefined) {
                    throw new Error("option.var is undefined. Code bug");
                  }

                  if (option.var[option.var.length - 1].length > 0) {
                    option.var[option.var.length - 1] =
                      option.var[option.var.length - 1] + " " + token;
                  } else {
                    option.var[option.var.length - 1] = token;
                  }
                  break;

                case "min":
                  option.min = parseInt(token);
                  break;

                case "max":
                  option.max = parseInt(token);
                  break;

                case "name":
                  option.name += option.name.length > 0 ? " " + token : token;
                  break;

                case "default":
                  option.default +=
                    (option.default as string).length > 0 ? " " + token : token;
                  break;
              }
            } else {
              console.log(
                "UCI",
                "Unknown option flag",
                "[" + token + "]",
                command
              );
            }
          }

          if (option.type === "spin") {
            // convert default/min/max to numbers
            if (typeof option.default !== "undefined") {
              option.default = parseInt(option.default as string, 10);
            }
          } else if (option.type === "check") {
            if (typeof option.default !== "undefined") {
              option.default = option.default === "true";
            }
          }
          ret.options.push(option);
        }
      }
    }

    return ret;
  }

  debug(debug: boolean): void {
    this.processPendingEngineCommands();
    this.engine.sendCommand("debug " + (debug ? "on" : "off") + "\n");
  }

  async isready(): Promise<void> {
    this.processPendingEngineCommands();
    this.engine.sendCommand("isready\n");

    for (;;) {
      const command = await this.getNextEngineCommand();
      if (command === "readyok") {
        return;
      }

      console.log("UCI", "Unexpected command in response to isready:", command);
    }
  }

  setoption(name: string, value: string | number | boolean): void {
    this.processPendingEngineCommands();
    this.engine.sendCommand(
      "setoption name " + name + " value " + value.toString() + "\n"
    );
  }

  async ucinewgame(): Promise<void> {
    this.processPendingEngineCommands();
    this.engine.sendCommand("ucinewgame\n");

    await this.isready();
  }

  position(moves: string[], fenstring?: string, startpos?: boolean): void {
    this.processPendingEngineCommands();

    if (moves.length !== 0 && fenstring !== undefined) {
      throw new Error("Bad command format");
    }

    let command = "position";
    if (typeof fenstring !== "undefined") {
      if (typeof startpos !== "undefined" && startpos) {
        throw new Error("Cannot set both fenstring and startpos!");
      }

      command = command + " fen " + fenstring;
    } else if (startpos) {
      command = command + " startpos";
    }

    if (moves.length > 0) {
      command = command + " moves";

      for (const move of moves) {
        command = command + " " + move;
      }
    }

    command = command + "\n";
    this.engine.sendCommand(command);
  }

  // Returns [best move, ponder move]
  // REVIEW: Maybe onInfo should be observable?
  async go(
    options?: UciGoOptions,
    onInfo?: (info: string) => void
  ): Promise<[string, string | null]> {
    this.processPendingEngineCommands();

    let command = "go";
    if (typeof options !== "undefined") {
      if (typeof options.searchmoves !== "undefined") {
        if (options.searchmoves.length > 0) {
          command = command + " searchmoves";
          for (const searchmove of options.searchmoves) {
            command = command + " " + searchmove;
          }
        }
      }

      if (typeof options.ponder !== "undefined" && options.ponder) {
        command = command + " ponder";
      }

      if (typeof options.wtime !== "undefined") {
        command = command + " wtime " + options.wtime.toString();
      }

      if (typeof options.btime !== "undefined") {
        command = command + " btime " + options.btime.toString();
      }

      if (typeof options.winc !== "undefined") {
        command = command + " winc " + options.winc.toString();
      }

      if (typeof options.binc !== "undefined") {
        command = command + " binc " + options.binc.toString();
      }

      if (typeof options.movestogo !== "undefined") {
        command = command + " movestogo " + options.movestogo.toString();
      }

      if (typeof options.depth !== "undefined") {
        command = command + " depth " + options.depth.toString();
      }

      if (typeof options.nodes !== "undefined") {
        command = command + " nodes " + options.nodes.toString();
      }

      if (typeof options.mate !== "undefined") {
        command = command + " mate " + options.mate.toString();
      }

      if (typeof options.movetime !== "undefined") {
        command = command + " movetime " + options.movetime.toString();
      }

      if (typeof options.infinite !== "undefined" && options.infinite) {
        command = command + " infinite";
      }
    }

    this.engine.sendCommand(command + "\n");

    for (;;) {
      command = await this.getNextEngineCommand();
      let [token, remainder] = nextToken(command);
      if (token === "bestmove") {
        [token, remainder] = nextToken(remainder);
        const bestMove = token;

        [token, remainder] = nextToken(remainder);
        let ponderMove: string | null = null;
        if (token === "ponder") {
          [ponderMove, remainder] = nextToken(remainder);
        }

        return [bestMove, ponderMove];
      } else if (token === "info") {
        if (typeof onInfo !== "undefined") {
          onInfo(remainder); // REVIEW: Parse this!
        }
      } else {
        console.log("UCI", "Unexpected command in response to go:", command);
      }
    }
  }

  stop(): void {
    this.engine.sendCommand("stop\n");
  }

  ponderhit(): void {
    this.engine.sendCommand("ponderhit\n");
  }

  quit(): void {
    this.engine.sendCommand("quit\n");
  }

  private processPendingEngineCommands() {
    if (this.pendingCommands.length > 0) {
      throw new Error("Unexpected UCI engine commands - throwing");
    }
  }

  private getNextEngineCommand(): Promise<string> {
    if (this.pendingCommands.length > 0) {
      const promise = Promise.resolve(this.pendingCommands[0]);
      this.pendingCommands.splice(0, 1);

      return promise;
    } else {
      // we are waiting for engine comamnds to come in
      const promise = new Promise<string>((resolve, reject) => {
        this.pendingCommandResolve = resolve;
        this.pendingCommandReject = reject;
      });

      return promise;
    }
  }

  private onEngineCommand(command: string): void {
    command = command.trim();

    if (this.pendingCommandResolve !== null) {
      if (this.pendingCommands.length > 0) {
        throw new Error("Pending UCI command array is not empty");
      }

      const resolve = this.pendingCommandResolve;
      this.pendingCommandResolve = null;
      this.pendingCommandResolve = null;

      resolve(command);
    } else {
      this.pendingCommands.push(command);
    }
  }
}

// REVIEW: Not yet implemented
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const timeout = <T>(promise: Promise<T>, timeoutms: number): Promise<T> =>
  promise;

const nextToken = (command: string): [string, string] => {
  const re = /^\s*(\S*)\s*(.*)$/;
  const found = re.exec(command);
  if (found === null) {
    return ["", ""];
  }

  return [found[1], found[2]];
};

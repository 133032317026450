import { Injectable } from "@angular/core";

export type BoardOrientation = "white" | "black" | "self";

export interface Settings {
  highlightValidMoves: boolean;
  highlightAttackers: boolean;
  highlightMoutain: boolean;
  highlightSquaresAttackedByOpposingPlayer: boolean;
  hightlightSquaresAttackedByDark: boolean;
  highlightSquaresAttackedByLight: boolean;
  showAttackerBadges: boolean;
  bottomPlayer: BoardOrientation;
}

// REVIEW: These options/settings should be persisted

@Injectable({
  providedIn: "root",
})
export class GameOptionsService {
  public highlightValidMoves: boolean;
  public highlightAttackers: boolean;
  public highlightMoutain: boolean;
  public highlightSquaresAttackedByOpposingPlayer: boolean;
  public hightlightSquaresAttackedByDark: boolean;
  public highlightSquaresAttackedByLight: boolean;
  public showAttackerBadges: boolean;
  public bottomPlayer: BoardOrientation;

  constructor() {
    this.highlightValidMoves = false;
    this.highlightAttackers = false;
    this.highlightMoutain = false; // REVIEW: NYI
    this.highlightSquaresAttackedByOpposingPlayer = false; // REVIEW: NYI
    this.hightlightSquaresAttackedByDark = false; // REVIEW: NYI
    this.highlightSquaresAttackedByLight = false; // REVIEW: NYI
    this.showAttackerBadges = false;
    this.bottomPlayer = "self";
  }

  get settings(): Settings {
    return {
      highlightValidMoves: this.highlightValidMoves,
      highlightAttackers: this.highlightAttackers,
      highlightMoutain: this.highlightMoutain,
      highlightSquaresAttackedByOpposingPlayer: this
        .highlightSquaresAttackedByOpposingPlayer,
      hightlightSquaresAttackedByDark: this.hightlightSquaresAttackedByDark,
      highlightSquaresAttackedByLight: this.highlightSquaresAttackedByLight,
      showAttackerBadges: this.showAttackerBadges,
      bottomPlayer: this.bottomPlayer,
    };
  }

  set settings(value: Settings) {
    this.highlightValidMoves = value.highlightValidMoves;
    this.highlightAttackers = value.highlightAttackers;
    this.highlightMoutain = value.highlightMoutain;
    this.highlightSquaresAttackedByOpposingPlayer =
      value.highlightSquaresAttackedByOpposingPlayer;
    this.hightlightSquaresAttackedByDark =
      value.hightlightSquaresAttackedByDark;
    this.highlightSquaresAttackedByLight =
      value.highlightSquaresAttackedByLight;
    this.showAttackerBadges = value.showAttackerBadges;
    this.bottomPlayer = value.bottomPlayer;
  }
}

import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";

import { MatDialog } from "@angular/material/dialog";

import { AuthenticationService } from "../authentication.service";
import { AuthenticationDialogComponent } from "../authentication-dialog/authentication-dialog.component";
import { FirebaseAuthService } from "../firebase-auth.service";
import { User } from "../firestore.service";

@Component({
  selector: "app-welcome-user",
  templateUrl: "./welcome-user.component.html",
  styleUrls: ["./welcome-user.component.scss"],
})
export class WelcomeUserComponent implements OnInit, OnDestroy {
  private queryParamMapSubscription: Subscription | undefined;

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    public auth: AuthenticationService,
    public fireauth: FirebaseAuthService
  ) {}

  ngOnInit(): void {
    // Process auth URL query parameters
    this.queryParamMapSubscription = this.route.queryParamMap.subscribe(
      (map) => {
        const mode = map.get("mode");
        const oobCode = map.get("oobCode");
        if (mode !== null && oobCode !== null) {
          this.signin(mode, oobCode);
        }
      }
    );
  }

  ngOnDestroy(): void {
    if (this.queryParamMapSubscription !== undefined) {
      this.queryParamMapSubscription.unsubscribe();
    }
  }

  safeDisplayName(profile: User): string {
    if (profile.displayName !== null) {
      return profile.displayName;
    } else {
      // REVIEW: We can do better...
      return profile.id;
    }
  }

  async signout(): Promise<void> {
    await this.fireauth.signOut();
  }

  signin(mode?: string, oobCode?: string): void {
    this.dialog.open(AuthenticationDialogComponent, {
      autoFocus: true,
      data: { mode, oobCode },
    });
  }
}

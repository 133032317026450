import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DarkModeListenerService implements OnDestroy {
  private darkModeListener?: (event: MediaQueryListEvent) => void;

  darkMode: BehaviorSubject<"dark" | "light">;

  constructor() {
    // Default to the light theme
    let theme: "dark" | "light" = "light";

    if (window.matchMedia) {
      // Function to catch color scheme preference changes
      this.darkModeListener = (e: MediaQueryListEvent) => {
        if (typeof this.darkMode !== "undefined") {
          const theme = e.matches ? "dark" : "light";
          if (theme !== this.darkMode.value) {
            this.darkMode.next(theme);
          }
        }
      };

      // Get the current color scheme preference from the browser
      if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
        theme = "dark";
      }
    }

    // Create the Subject
    this.darkMode = new BehaviorSubject<"dark" | "light">(theme);

    // Attach an event listener for color scheme changes
    if (window.matchMedia && this.darkModeListener !== undefined) {
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .addEventListener("change", this.darkModeListener);
    }
  }

  ngOnDestroy(): void {
    // Detach the event listener
    if (this.darkModeListener) {
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .removeEventListener("change", this.darkModeListener);
    }

    this.darkMode.complete();
  }
}

import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import {
  FirebaseAuthService,
  FirebaseUnsubscribe,
  FirebaseUser,
} from "../firebase-auth.service";
import {
  FirestoreError,
  FirestoreService,
  SERVER_NOW,
} from "../firestore.service";

@Component({
  selector: "app-join-game",
  templateUrl: "./join-game.component.html",
  styleUrls: ["./join-game.component.scss"],
})
export class JoinGameComponent implements OnInit, OnDestroy {
  public message: string;

  private invitationCode: string;
  private paramMapSubscription: Subscription | undefined;
  private authSubscription: FirebaseUnsubscribe | undefined;

  private joinedGame: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private auth: FirebaseAuthService,
    private firestore: FirestoreService
  ) {
    this.invitationCode = "";
    this.joinedGame = false;
    this.message = "";
  }

  ngOnInit(): void {
    this.paramMapSubscription = this.route.paramMap.subscribe((params) => {
      // Cast since the param is part of the route; we won't get here without.
      this.invitationCode = params.get("invitationCode") as string;
      this.message = "Invitation Code: " + this.invitationCode;
    });

    const user = this.auth.currentUser;
    if (user !== null) {
      void this.joinGame(user);
    } else {
      this.message = "Please log in (top right corner)";
      this.authSubscription = this.auth.onAuthStateChanged((user) => {
        if (user !== null) {
          void this.joinGame(user);
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (typeof this.paramMapSubscription !== "undefined") {
      this.paramMapSubscription.unsubscribe();
    }

    if (typeof this.authSubscription !== "undefined") {
      this.authSubscription();
    }
  }

  private async joinGame(user: FirebaseUser): Promise<void> {
    if (this.joinedGame) {
      return;
    }
    this.joinedGame = true;

    try {
      // Add my user id to the waiting room
      await this.firestore.waitingRoomDoc(this.invitationCode).update({
        player2: user.uid,
        player2JoinedAt: SERVER_NOW,
      });
      this.waitForStart();
    } catch (err) {
      this.message = "Error: " + (err as FirestoreError).message;
    }
  }

  private waitForStart(): void {
    this.message = "Waiting for opponent to start the game...";
    const subscription = this.firestore
      .waitingRoomDoc(this.invitationCode)
      .onSnapshot((snapshot) => {
        const snapshotData = snapshot.data();
        if (typeof snapshotData !== "undefined") {
          if (
            typeof snapshotData.gameId !== "undefined" &&
            snapshotData.gameId !== null
          ) {
            this.message = "Starting!!!";
            void this.router.navigate(["/game", snapshotData.gameId]);
            subscription();
          }
        }
      });
  }
}

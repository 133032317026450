import { Component, Input } from "@angular/core";
import { User } from "../firestore.service";

@Component({
  selector: "app-user",
  templateUrl: "./user.component.html",
  styleUrls: ["./user.component.scss"],
})
export class UserComponent {
  @Input()
  public user: User | null;

  constructor() {
    this.user = null;
  }

  get valid(): boolean {
    return this.user !== null;
  }

  get userProfile(): User {
    if (this.user === null) {
      throw new Error("No user profile");
    }

    return this.user;
  }

  get safeDisplayName(): string {
    return this.userProfile.displayName !== null
      ? this.userProfile.displayName
      : this.userProfile.id;
  }

  get safePhotoURL(): string | null {
    // We hide the storage URL here in case the user recently went through a profile image change
    if (
      this.userProfile.photoURL &&
      !this.userProfile.photoURL.startsWith("users/avatars/")
    ) {
      return this.userProfile.photoURL;
    } else {
      return null;
    }
  }

  get routerLink(): string {
    return "/profile/" + this.userProfile.id;
  }
}

import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { GameComponent } from "./game/game.component";
import { HomeComponent } from "./home/home.component";
import { JoinGameComponent } from "./join-game/join-game.component";
import { ProfileComponent } from "./profile/profile.component";

const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "game/:gameId", component: GameComponent },
  { path: "profile/:userId", component: ProfileComponent },
  { path: "join/:invitationCode", component: JoinGameComponent },
  // REVIEW: NYI
  // { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

<h1 mat-dialog-title>Settings</h1>
<mat-dialog-content>
  <table>
    <mat-radio-group [(ngModel)]="data.bottomPlayer" id="bottomPlayer">
      <tr>
        <td>
          <mat-radio-button value="self">Self on bottom</mat-radio-button>
        </td>
        <td>
          <mat-radio-button value="white">White on bottom</mat-radio-button>
        </td>
      </tr>
      <tr>
        <mat-radio-button value="black">Black on bottom</mat-radio-button>
        <td></td>
      </tr>
    </mat-radio-group>
    <tr>
      <td>
        <mat-checkbox
          [(ngModel)]="data.highlightValidMoves"
          id="highlightValidMoves"
          >Highlight valid moves</mat-checkbox
        >
      </td>
      <td>
        <mat-checkbox
          [(ngModel)]="data.highlightAttackers"
          id="highlightAttackers"
          >Highlight pieces attacking this square
        </mat-checkbox>
      </td>
    </tr>
    <tr>
      <td>
        <mat-checkbox
          [(ngModel)]="data.highlightSquaresAttackedByOpposingPlayer"
          id="highlightSquaresAttackedByOpposingPlayer"
        >
          Highlight squares attacked by the opposite player</mat-checkbox
        >
      </td>
      <td>
        <mat-checkbox [(ngModel)]="data.highlightMoutain" id="highlightMoutain"
          >Highlight moutain and hills</mat-checkbox
        >
      </td>
    </tr>
    <tr>
      <td>
        <mat-checkbox
          [(ngModel)]="data.hightlightSquaresAttackedByDark"
          id="hightlightSquaresAttackedByDark"
        >
          Highlight squares attacked by dark</mat-checkbox
        >
      </td>
      <td>
        <mat-checkbox
          [(ngModel)]="data.highlightSquaresAttackedByLight"
          id="highlightSquaresAttackedByLight"
        >
          Highlight squares attacked by light</mat-checkbox
        >
      </td>
    </tr>
    <tr>
      <td>
        <mat-checkbox
          [(ngModel)]="data.showAttackerBadges"
          id="showAttackerBadges"
          >Show badges</mat-checkbox
        >
      </td>
      <td></td>
    </tr>
  </table>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-stroked-button mat-dialog-close id="cancel">CANCEL</button>
  <button
    mat-flat-button
    color="primary"
    [mat-dialog-close]="data"
    cdfFocusInitial
    id="ok"
  >
    OK
  </button>
</mat-dialog-actions>

import { Injectable } from "@angular/core";

import firebase from "firebase/app";
import "firebase/firestore";

import { FirestoreService, User, Game } from "./firestore.service";

@Injectable({
  providedIn: "root",
})
export class ChessServerService {
  constructor(private firestore: FirestoreService) {}

  // REVIEW: I think this can move into the Firestore service
  public async fetchGames(): Promise<Game[]> {
    const querysnapshot = await this.firestore
      .gameCollection()
      .orderBy("lastMoveDate", "desc") // REVIEW: Where does this put new games without a last move?
      .get();

    if (querysnapshot.docs.length === 0) {
      return [];
    }

    // collect all the unique user ids
    const userIds: Record<string, undefined> = {};
    for (const game of querysnapshot.docs) {
      const gameData = game.data();
      userIds[gameData.white as string] = undefined;
      userIds[gameData.black as string] = undefined;
    }

    // REVIEW: Batch these user queries to use the in operator in a where call. up to
    // 10 ids per call!

    // Fetch the user docs
    const outstandingUserQueries: Promise<
      firebase.firestore.DocumentSnapshot<User>
    >[] = [];
    for (const userId of Object.keys(userIds)) {
      outstandingUserQueries.push(this.firestore.userDoc(userId).get());
    }

    // collect all the users
    const users: Record<string, User | undefined> = {};
    for (const userDocSnapshot of await Promise.all(outstandingUserQueries)) {
      const user = userDocSnapshot.data();
      if (user) {
        // store the user in our map
        users[user.id] = user;
      }
    }

    const games: Game[] = [];
    for (const gameDoc of querysnapshot.docs) {
      const game = gameDoc.data();

      const user1 = users[game.black as string];
      const user2 = users[game.white as string];

      if (user1 !== undefined && user2 !== undefined) {
        // Replace the user doc reference with the User object
        game.black = user1;
        game.white = user2;

        games.push(game);
      }
    }

    return games;
  }

  // Returns the gameID
  public async createNewGame(game: Game): Promise<string> {
    const gameDocRef = await this.firestore.gameCollection().add(game);
    return gameDocRef.id;
  }
}

/* eslint @typescript-eslint/no-unsafe-member-access: "off", @typescript-eslint/no-unsafe-assignment: "off" */

import { Injectable } from "@angular/core";

import firebase from "firebase/app";
import "firebase/firestore";
import { Observable } from "rxjs";

export type FirestoreError = firebase.firestore.FirestoreError;
export const FirestoreServerTimestamp =
  // eslint-disable-next-line @typescript-eslint/unbound-method
  firebase.firestore.FieldValue.serverTimestamp;

// Marker value used to indicate 'now'
export const SERVER_NOW = new Date(3000, 1, 1, 1, 1, 1);

// These are the user profile fields that are visible to other players
// REVIEW: Rename to UserDoc
export interface User {
  id: string;
  active: boolean;
  displayName: string | null;
  joinDate: Date;
  photoURL: string | null;
  public: boolean;
  uci: string | null;
}

// These profile fields are only visible to this player
// REVIEW: Rename to UserPrivateDoc
export interface UserPrivate {
  id: string;
  providerId: string;
  email: string | null;
  phoneNumber: string | null;
}

export enum File {
  a = "a",
  b = "b",
  c = "c",
  d = "d",
  e = "e",
  f = "f",
  g = "g",
  h = "h",
}

export interface Square {
  file: File;
  rank: number;
}

export interface Move {
  ordinal: number;
  date: Date;
  from: Square;
  to: Square;
  notation: string;
}

// REVIEW: Rename to GameResult
export type Result = "0-1" | "1-0" | "1/2-1/2" | "*";

// Game document object
// REVIEW: Rename to GameDoc
export interface Game {
  // GameID in firestore
  id: string;

  // Public games show up for all users when browsing
  public: boolean;

  // UCI engine options for computer matches
  engineOptions?: Array<{ name: string; value: string | number | boolean }>;

  // The seven tag roster:
  event: string; // If no event, '?'
  site: string; // '{City}[, {Region}] {IOC Country}'. '?' if unknown
  date: Date; // YYYY.MM.DD in time local to site
  round: string;
  white: string | User; // White in the STR
  black: string | User; // Black in the STR
  result: Result;

  // Supplemental tags
  tags: Record<string, string>;

  // Denormalized information regarding the last move
  lastMove?: string;
  lastMoveDate: Date | null;
}

// Waiting room document object
export interface WaitingRoomDoc {
  id: string;

  createdAt: Date;
  createdBy: string; // uid

  player2: string | null; // uid
  player2JoinedAt: Date | null;

  gameId: string | null;
  gameStartTime: Date | null;
}

export function observableOnSnapshot<T>(
  docRef: firebase.firestore.DocumentReference<T>,
  options?: firebase.firestore.SnapshotListenOptions
): Observable<firebase.firestore.DocumentSnapshot<T>>;
export function observableOnSnapshot<T>(
  query: firebase.firestore.Query<T>,
  options?: firebase.firestore.SnapshotListenOptions
): Observable<firebase.firestore.QuerySnapshot<T>>;
export function observableOnSnapshot<T>(
  thing: firebase.firestore.DocumentReference<T> | firebase.firestore.Query<T>,
  options?: firebase.firestore.SnapshotListenOptions
): unknown {
  return new Observable((subscriber) => {
    let teardown: () => void | undefined;
    if (thing instanceof firebase.firestore.Query) {
      if (options !== undefined) {
        teardown = thing.onSnapshot(options, subscriber);
      } else {
        teardown = thing.onSnapshot(subscriber);
      }
    } else {
      if (options !== undefined) {
        teardown = thing.onSnapshot(options, subscriber);
      } else {
        teardown = thing.onSnapshot(subscriber);
      }
    }

    return teardown;
  });
}

@Injectable({
  providedIn: "root",
})
export class FirestoreService {
  private db: firebase.firestore.Firestore;
  private userConverter: UserConverter;
  private userPrivateConverter: UserPrivateConverter;
  private gameConverter: GameConverter;
  private moveConverter: MoveConverter;
  private waitingRoomConverter: WaitingRoomDocConverter;

  constructor() {
    this.userConverter = new UserConverter();
    this.userPrivateConverter = new UserPrivateConverter();
    this.gameConverter = new GameConverter();
    this.moveConverter = new MoveConverter();
    this.waitingRoomConverter = new WaitingRoomDocConverter();

    // REVIEW: Do I need to pass the app?
    this.db = firebase.firestore();
  }

  batch(): firebase.firestore.WriteBatch {
    return this.db.batch();
  }

  userCollection(): firebase.firestore.CollectionReference<User> {
    return this.db.collection("users").withConverter(this.userConverter);
  }

  userDoc(id: string): firebase.firestore.DocumentReference<User> {
    return this.db.doc("users/" + id).withConverter(this.userConverter);
  }

  userPrivateDoc(
    id: string
  ): firebase.firestore.DocumentReference<UserPrivate> {
    return this.db
      .doc("usersPrivate/" + id)
      .withConverter(this.userPrivateConverter);
  }

  gameCollection(): firebase.firestore.CollectionReference<Game> {
    return this.db.collection("games").withConverter(this.gameConverter);
  }

  gameDoc(id: string): firebase.firestore.DocumentReference<Game> {
    return this.db.doc("games/" + id).withConverter(this.gameConverter);
  }

  gameMoves(
    gameDoc: firebase.firestore.DocumentReference<Game>
  ): firebase.firestore.CollectionReference<Move> {
    return gameDoc.collection("moves").withConverter(this.moveConverter);
  }

  waitingRoomDoc(
    id: string
  ): firebase.firestore.DocumentReference<WaitingRoomDoc> {
    return this.db
      .doc("waitingRoom/" + id)
      .withConverter(this.waitingRoomConverter);
  }

  doc(
    documentPath: string
  ): firebase.firestore.DocumentReference<firebase.firestore.DocumentData> {
    return this.db.doc(documentPath);
  }
}

const dateToTimestamp = (
  date: Date
): firebase.firestore.FieldValue | firebase.firestore.Timestamp => {
  if (date === SERVER_NOW) {
    return firebase.firestore.FieldValue.serverTimestamp();
  }

  return firebase.firestore.Timestamp.fromDate(date);
};

const timestampToDate = (timestamp: firebase.firestore.Timestamp) =>
  timestamp.toDate();

const emptyStringToNull = (s: string | null) => {
  if (s === null) {
    return null;
  } else if (s.length === 0) {
    return null;
  } else {
    return s;
  }
};

class UserConverter implements firebase.firestore.FirestoreDataConverter<User> {
  toFirestore(user: User): firebase.firestore.DocumentData;
  toFirestore(
    user: Partial<User>,
    options: firebase.firestore.SetOptions
  ): firebase.firestore.DocumentData;
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
  toFirestore(user: any, options?: any): firebase.firestore.DocumentData {
    // REVIEW: Ignoring SetOptions.
    const firestoreObject: firebase.firestore.DocumentData = {};

    if (typeof user.active !== "undefined") {
      firestoreObject.active = user.active;
    }

    if (typeof user.displayName !== "undefined") {
      firestoreObject.displayName = emptyStringToNull(user.displayName);
    }

    if (typeof user.joinDate !== "undefined") {
      firestoreObject.joinDate = dateToTimestamp(user.joinDate);
    }

    if (typeof user.photoURL !== "undefined") {
      firestoreObject.photoURL = emptyStringToNull(user.photoURL);
    }

    if (typeof user.public !== "undefined") {
      firestoreObject.public = user.public;
    }

    if (typeof user.uci !== "undefined") {
      firestoreObject.uci = emptyStringToNull(user.uci);
    }

    return firestoreObject;
  }

  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    options: firebase.firestore.SnapshotOptions
  ): User {
    return {
      id: snapshot.id,
      active: snapshot.get("active") as boolean,
      displayName: (snapshot.get("displayName") ?? null) as string | null,
      joinDate: timestampToDate(
        snapshot.get("joinDate", {
          serverTimestamps: "estimate",
        }) as firebase.firestore.Timestamp
      ),
      photoURL: (snapshot.get("photoURL") ?? null) as string | null,
      public: snapshot.get("public") as boolean,
      uci: (snapshot.get("uci") ?? null) as string | null,
    };
  }
}

class UserPrivateConverter
  implements firebase.firestore.FirestoreDataConverter<UserPrivate> {
  toFirestore(user: UserPrivate): firebase.firestore.DocumentData;
  toFirestore(
    user: Partial<UserPrivate>,
    options: firebase.firestore.SetOptions
  ): firebase.firestore.DocumentData;
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
  toFirestore(user: any, options?: any) {
    // REVIEW: Ignoring SetOptions.
    const firestoreObject: firebase.firestore.DocumentData = {};

    if (typeof user.providerId !== "undefined") {
      firestoreObject.providerId = user.providerId;
    }

    if (typeof user.email !== "undefined") {
      firestoreObject.email = emptyStringToNull(user.email);
    }

    if (typeof user.phoneNumber !== "undefined") {
      firestoreObject.phoneNumber = emptyStringToNull(user.phoneNumber);
    }

    return firestoreObject;
  }

  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    options: firebase.firestore.SnapshotOptions
  ): UserPrivate {
    return {
      id: snapshot.id,
      providerId: snapshot.get("providerId") as string,
      email: snapshot.get("email") as string | null,
      phoneNumber: snapshot.get("phoneNumber") as string | null,
    };
  }
}

class GameConverter implements firebase.firestore.FirestoreDataConverter<Game> {
  toFirestore(game: Game): firebase.firestore.DocumentData;
  toFirestore(
    game: Partial<Game>,
    options: firebase.firestore.SetOptions
  ): firebase.firestore.DocumentData;
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
  toFirestore(game: any, options?: any) {
    // REVIEW: Not fully implemented. Ignoring SetOptions.
    const firestoreObject: firebase.firestore.DocumentData = {};

    if (typeof game.public !== "undefined") {
      firestoreObject.public = game.public;
    }

    if (typeof game.event !== "undefined") {
      firestoreObject.event = game.event;
    }

    if (typeof game.site !== "undefined") {
      firestoreObject.site = game.site;
    }

    if (typeof game.date !== "undefined") {
      firestoreObject.date = dateToTimestamp(game.date);
    }

    if (typeof game.round !== "undefined") {
      firestoreObject.round = game.round;
    }

    if (typeof game.white !== "undefined") {
      if (typeof game.white === "object") {
        firestoreObject.white = game.white.id;
      } else {
        firestoreObject.white = game.white;
      }
    }

    if (typeof game.black !== "undefined") {
      if (typeof game.black === "object") {
        firestoreObject.black = game.black.id;
      } else {
        firestoreObject.black = game.black;
      }
    }

    if (typeof game.result !== "undefined") {
      firestoreObject.result = game.result;
    }

    if (typeof game.tags !== "undefined") {
      firestoreObject.tags = game.tags;
    }

    if (typeof game.engineOptions !== "undefined") {
      firestoreObject.engineOptions = game.engineOptions;
    }

    if (typeof game.lastMove !== "undefined") {
      firestoreObject.lastMove = game.lastMove;
    }

    if (typeof game.lastMoveDate !== "undefined") {
      firestoreObject.lastMoveDate =
        game.lastMoveDate !== null ? dateToTimestamp(game.lastMoveDate) : null;
    }

    return firestoreObject;
  }

  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    options: firebase.firestore.SnapshotOptions
  ): Game {
    return {
      id: snapshot.id,
      public: snapshot.get("public") as boolean,
      event: snapshot.get("event") as string,
      site: snapshot.get("site") as string,
      date: timestampToDate(
        snapshot.get("date", {
          serverTimestamps: "estimate",
        }) as firebase.firestore.Timestamp
      ),
      round: snapshot.get("round") as string,
      white: snapshot.get("white") as string,
      black: snapshot.get("black") as string,
      result: snapshot.get("result") as Result,
      engineOptions: snapshot.get("engineOptions") as
        | Array<{ name: string; value: string | number | boolean }>
        | undefined,
      tags: snapshot.get("tags") as Record<string, string>,
      lastMove: snapshot.get("lastMove") as string | undefined,
      lastMoveDate:
        snapshot.get("lastMoveDate") !== null
          ? timestampToDate(
              snapshot.get("lastMoveDate", {
                serverTimestamps: "estimate",
              }) as firebase.firestore.Timestamp
            )
          : null,
    };
  }
}

class MoveConverter implements firebase.firestore.FirestoreDataConverter<Move> {
  toFirestore(move: Move): firebase.firestore.DocumentData;
  toFirestore(
    move: Partial<Move>,
    options: firebase.firestore.SetOptions
  ): firebase.firestore.DocumentData;
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
  toFirestore(move: any, options?: any) {
    // REVIEW: Not fully implemented. Ignoring the partial aspect as well as the SetOptions.
    const firestoreObject: firebase.firestore.DocumentData = {
      ordinal: move.ordinal,
      date: dateToTimestamp(move.date),
      from: move.from,
      to: move.to,
      notation: move.notation,
    };

    return firestoreObject;
  }

  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    options: firebase.firestore.SnapshotOptions
  ): Move {
    return {
      ordinal: snapshot.get("ordinal") as number,
      date: timestampToDate(
        snapshot.get("date", {
          serverTimestamps: "estimate",
        }) as firebase.firestore.Timestamp
      ),
      from: snapshot.get("from") as Square,
      to: snapshot.get("to") as Square,
      notation: snapshot.get("notation") as string,
    };
  }
}

class WaitingRoomDocConverter
  implements firebase.firestore.FirestoreDataConverter<WaitingRoomDoc> {
  toFirestore(modelObject: WaitingRoomDoc): firebase.firestore.DocumentData;
  toFirestore(
    modelObject: Partial<WaitingRoomDoc>,
    options: firebase.firestore.SetOptions
  ): firebase.firestore.DocumentData;
  toFirestore(
    modelObject: unknown,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    options?: unknown
  ): firebase.firestore.DocumentData {
    // REVIEW: Not fully implemented. Ignoring SetOptions.
    const firestoreObject: firebase.firestore.DocumentData = {};

    const waitingRoom = modelObject as Partial<WaitingRoomDoc>;
    if (typeof waitingRoom.createdAt !== "undefined") {
      firestoreObject.createdAt = dateToTimestamp(waitingRoom.createdAt);
    }

    if (typeof waitingRoom.createdBy !== "undefined") {
      firestoreObject.createdBy = waitingRoom.createdBy;
    }

    if (typeof waitingRoom.player2 !== "undefined") {
      if (waitingRoom.player2 !== null) {
        firestoreObject.player2 = waitingRoom.player2;
      } else {
        firestoreObject.player2 = null;
      }
    }

    if (typeof waitingRoom.player2JoinedAt !== "undefined") {
      if (waitingRoom.player2JoinedAt !== null) {
        firestoreObject.player2JoinedAt = dateToTimestamp(
          waitingRoom.player2JoinedAt
        );
      } else {
        firestoreObject.player2JoinedAt = null;
      }
    }

    if (typeof waitingRoom.gameId !== "undefined") {
      firestoreObject.gameId = waitingRoom.gameId;
    }

    if (typeof waitingRoom.gameStartTime !== "undefined") {
      if (waitingRoom.gameStartTime !== null) {
        firestoreObject.gameStartTime = dateToTimestamp(
          waitingRoom.gameStartTime
        );
      } else {
        firestoreObject.gameStartTime = null;
      }
    }

    return firestoreObject;
  }

  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    options: firebase.firestore.SnapshotOptions
  ): WaitingRoomDoc {
    const player2JoinedAt = snapshot.get("player2JoinedAt", {
      serverTimestamps: "estimate",
    }) as firebase.firestore.Timestamp | null;
    const gameStartTime = snapshot.get("gameStartTime", {
      serverTimestamps: "estimate",
    }) as firebase.firestore.Timestamp | null;

    return {
      id: snapshot.id,
      createdAt: timestampToDate(
        snapshot.get("createdAt", {
          serverTimestamps: "estimate",
        }) as firebase.firestore.Timestamp
      ),
      createdBy: snapshot.get("createdBy") as string,
      player2: snapshot.get("player2") as string | null,
      player2JoinedAt:
        player2JoinedAt !== null ? timestampToDate(player2JoinedAt) : null,
      gameId: snapshot.get("gameId") as string | null,
      gameStartTime:
        gameStartTime !== null ? timestampToDate(gameStartTime) : null,
    };
  }
}

export const testing = {
  dateToTimestamp,
  UserConverter,
  UserPrivateConverter,
  GameConverter,
  MoveConverter,
  WaitingRoomDocConverter,
};

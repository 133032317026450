import { Component, Input } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { GameOptionsService } from "../game-options.service";
import {
  Piece,
  Color,
  labelFromSquare,
  crackLabel,
  ChessError,
} from "../game.service";
import { GameSnapshot, Label } from "../game-update";

@Component({
  selector: "app-game-board",
  templateUrl: "./game-board.component.html",
  styleUrls: ["./game-board.component.scss"],
})
export class GameBoardComponent {
  private _tiles: GameBoardTileImpl[];
  private _invertedTiles: GameBoardTileImpl[];
  private movingSquare: Label | null;
  private _snapshot!: GameSnapshot;

  constructor(
    private gameOptions: GameOptionsService,
    private snackBar: MatSnackBar
  ) {
    this._tiles = [];
    this._invertedTiles = [];
    this.movingSquare = null;
  }

  @Input()
  public bottomPlayer!: Color;

  public get snapshot(): GameSnapshot {
    return this._snapshot;
  }

  @Input()
  public set snapshot(value: GameSnapshot) {
    this._snapshot = value;

    this._tiles = [];
    this._invertedTiles = [];
    this.movingSquare = null;

    // top left- empty
    this._tiles.push(new GameBoardTileImpl(value, -1, -1, 1, 1, "file-t", ""));

    // top file labels
    for (let file = 0; file < 8; file++) {
      this._tiles.push(
        new GameBoardTileImpl(
          value,
          -1,
          -1,
          1,
          5,
          "file-t",
          String.fromCharCode("a".charCodeAt(0) + file)
        )
      );
    }

    // top right- empty
    this._tiles.push(new GameBoardTileImpl(value, -1, -1, 1, 1, "file-t", ""));

    // tiles for the main board
    for (let rank = 8; rank > 0; rank--) {
      // rank label, left
      this._tiles.push(
        new GameBoardTileImpl(value, -1, -1, 5, 1, "rank-l", rank.toString())
      );

      for (let file = 1; file < 9; file++) {
        this._tiles.push(
          new GameBoardTileImpl(value, file, rank, 5, 5, null, "")
        );
      }

      // rank label, right
      this._tiles.push(
        new GameBoardTileImpl(value, -1, -1, 5, 1, "rank-r", rank.toString())
      );
    }

    // bottom left- empty
    this._tiles.push(new GameBoardTileImpl(value, -1, -1, 1, 1, "file-b", ""));

    // bottom file labels
    for (let file = 0; file < 8; file++) {
      this._tiles.push(
        new GameBoardTileImpl(
          value,
          -1,
          -1,
          1,
          5,
          "file-b",
          String.fromCharCode("a".charCodeAt(0) + file)
        )
      );
    }

    // bottom right- empty
    this._tiles.push(new GameBoardTileImpl(value, -1, -1, 1, 1, "file-b", ""));

    // create the reversed view to use when black is at the bottom of the board
    this._invertedTiles = reverse(this._tiles);

    this.clearAnnotations();

    // Annotate the last move squares
    if (value.history.length > 0) {
      const lastMove = value.history[value.history.length - 1];
      this.annotate([lastMove.from, lastMove.to], "last-move");
    }
  }

  private annotate(
    squares: Label[] | null,
    add: string,
    remove?: string
  ): void {
    if (squares !== null) {
      squares.forEach((label) => {
        const square = crackLabel(label);
        const index = (9 - square.rank) * 10 + square.file;

        this._tiles[index].annotate(add, remove);
      });
    } else {
      this._tiles.forEach((tile) => tile.annotate(add, remove));
    }
  }

  // REVIEW: I should rename this
  private clearAnnotations(): void {
    for (const tile of this._tiles) {
      if (tile.file === -1) {
        continue;
      }

      tile.clearAnnotations();

      // Update the attacker badge
      let light = 0;
      let dark = 0;

      const attackingSquares = this._snapshot.attackers[tile.square];

      if (attackingSquares !== undefined) {
        for (const attacker of attackingSquares) {
          const piece = this._snapshot.squares[attacker];
          if (piece !== undefined) {
            if (piece.color === Color.Dark) {
              dark += 1;
            } else {
              light += 1;
            }
          }
        }
      }

      tile.setAttackers(light, dark);
    }
  }

  get tiles(): GameBoardTile[] {
    // the _tiles property is always stored from the top left to the bottom right,
    // with black on top and white on bottom.
    // we reverse the list when needed to show the black player on the bottom.
    if (this.bottomPlayer === Color.Dark) {
      return this._invertedTiles;
    } else {
      return this._tiles;
    }
  }

  get turn(): Color | null {
    return this._snapshot.turn;
  }

  get hideBadges(): boolean {
    return !this.gameOptions.showAttackerBadges;
  }

  get playingAs(): Color | null {
    return this._snapshot.playingAs;
  }

  private moveTo(
    fromFile: number,
    fromRank: number,
    toFile: number,
    toRank: number
  ): void {
    if (!this._snapshot.move(fromFile, fromRank, toFile, toRank)) {
      this.snackBar.open("Not a valid move");
    }

    this.clearAnnotations();
  }

  onClickPiece(file: number, rank: number): void {
    this.onClickSquare(file, rank);
  }

  onClickSquare(file: number, rank: number): void {
    if (file !== -1) {
      if (this.movingSquare === null) {
        const piece = this._snapshot.squares[labelFromSquare(file, rank)];
        if (piece !== undefined && piece.color === this.turn) {
          // "pick up" the piece
          this.movingSquare = labelFromSquare(file, rank);
          return;
        }
      } else {
        // Move the piece
        const square = crackLabel(this.movingSquare);
        void this.moveTo(square.file, square.rank, file, rank);
      }
    }

    this.movingSquare = null;
  }

  onMouseEnter(file: number, rank: number): void {
    if (file !== -1) {
      if (this.gameOptions.highlightAttackers) {
        const attackingSquares = this._snapshot.attackers[
          labelFromSquare(file, rank)
        ];
        if (attackingSquares !== undefined) {
          this.annotate(attackingSquares, "attacker");
        } else {
          this.annotate([], "attacker");
        }
      }

      if (this.gameOptions.highlightValidMoves) {
        for (const moves of Object.values(this._snapshot.moves)) {
          if (moves !== undefined) {
            this.annotate(moves, "valid-move");
          }
        }
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onMouseLeave(file: number, rank: number): void {
    this.annotate(null, "", "valid-move");
    this.annotate(null, "", "attacker");
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onDragStart(file: number, rank: number): void {
    this.movingSquare = labelFromSquare(file, rank);
  }

  private isValidDrop(file: number, rank: number, event: DragEvent): boolean {
    if (
      file === -1 ||
      this.movingSquare === null ||
      !event?.dataTransfer?.types.includes("chess/square")
    ) {
      return false;
    }

    const from = this.movingSquare;
    const moves = this._snapshot.moves[from];
    if (moves === undefined) {
      return false;
    }

    return moves.indexOf(labelFromSquare(file, rank)) !== -1;
  }

  onDragOver(file: number, rank: number, event: DragEvent): void {
    if (this.isValidDrop(file, rank, event)) {
      if (event?.dataTransfer !== null) {
        event.dataTransfer.dropEffect = "move";
      }

      event.preventDefault();
    } else if (event.dataTransfer !== null) {
      event.dataTransfer.dropEffect = "none";
    }
  }

  onDragEnter(file: number, rank: number, event: DragEvent): void {
    if (this.isValidDrop(file, rank, event)) {
      this.annotate([labelFromSquare(file, rank)], "dropTarget");

      if (event?.dataTransfer !== null) {
        event.dataTransfer.dropEffect = "move";
      }

      event.preventDefault();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onDragLeave(file: number, rank: number, event?: DragEvent): void {
    if (file !== -1) {
      this.annotate([labelFromSquare(file, rank)], "", "dropTarget");
    }
  }

  onDrop(file: number, rank: number, event: DragEvent): void {
    this.onDragLeave(file, rank, undefined);

    if (this.isValidDrop(file, rank, event)) {
      if (this.movingSquare !== null) {
        const square = crackLabel(this.movingSquare);
        void this.moveTo(square.file, square.rank, file, rank);
      }
    }

    this.movingSquare = null;
  }
}

export interface GameBoardTile {
  readonly file: number;
  readonly rank: number;
  readonly square: string;
  readonly piece: Piece | null;
  readonly className: string;
  readonly rowspan: number;
  readonly colspan: number;
  readonly contents: string;
  readonly attackers: string;
}

class GameBoardTileImpl implements GameBoardTile {
  private _file: number;
  private _rank: number;
  private _rowspan: number;
  private _colspan: number;
  private _className: string | null;
  private _contents: string;
  private _attackers: string;
  private _annotations: string;
  private _square: Label | null;
  private _piece: Piece | null;

  private _cachedClassName: string | null;

  constructor(
    gameSnapshot: GameSnapshot,
    file: number,
    rank: number,
    rowspan: number,
    colspan: number,
    className: string | null,
    contents: string
  ) {
    this._file = file;
    this._rank = rank;
    this._rowspan = rowspan;
    this._colspan = colspan;
    this._className = className;
    this._contents = contents;
    this._attackers = "";
    this._annotations = " ";
    this._cachedClassName = null;
    if (this.file !== -1) {
      this._square = labelFromSquare(file, rank);
      this._piece = gameSnapshot.squares[this._square] ?? null;
    } else {
      this._square = null;
      this._piece = null;
    }
  }

  annotate(add: string, remove?: string): void {
    if (typeof remove !== "undefined") {
      this._annotations = this._annotations.replace(" " + remove + " ", " ");
    }
    if (add.length > 0) {
      this._annotations += add + " ";
    }

    this._cachedClassName = null;
  }

  clearAnnotations(): void {
    this._annotations = " ";
    this._cachedClassName = null;
  }

  setAttackers(light: number, dark: number) {
    if (light > 0 && dark > 0) {
      this._attackers = `${light}:${dark}`;
    } else if (light > 0) {
      this._attackers = `${light}:`;
    } else if (dark > 0) {
      this._attackers = `:${dark}`;
    } else {
      this._attackers = "";
    }
  }

  get file(): number {
    return this._file;
  }

  get rank(): number {
    return this._rank;
  }

  get square(): Label {
    if (this._square === null) {
      throw new ChessError(
        "Tried to get label property from Tile with -1 file"
      );
    }

    return this._square;
  }

  get piece(): Piece | null {
    return this._piece;
  }

  get className(): string {
    if (this._cachedClassName === null) {
      if (this._className !== null) {
        this._cachedClassName = this._className;
      } else {
        const base =
          this._file % 2 === this._rank % 2 ? "square-d" : "square-l";
        this._cachedClassName =
          base + this._annotations.substring(0, this._annotations.length - 1);
      }
    }

    return this._cachedClassName;
  }

  get rowspan(): number {
    return this._rowspan;
  }

  get colspan(): number {
    return this._colspan;
  }

  get contents(): string {
    return this._contents;
  }

  get attackers(): string {
    return this._attackers;
  }
}

function reverse<T>(rg: Array<T>): Array<T> {
  // REVIEW: I hoped to do this just as a mapping, returning an object
  // with a custom index lookup funciton but I can't see how to do that,
  // so instead we just create a copy.

  const newRg: T[] = [];
  for (let i = rg.length - 1; i >= 0; i--) {
    newRg.push(rg[i]);
  }

  return newRg;
}

export const testing = { reverse };

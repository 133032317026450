<ng-container *ngIf="game$ | async; let game; else loading">
  <ng-container [ngSwitch]="game.status">
    <ng-container *ngSwitchCase="'loaded'">
      <ng-container
        *ngTemplateOutlet="gameTemplate; context: game"
      ></ng-container
    ></ng-container>
    <ng-container *ngSwitchCase="'error'"
      ><ng-container *ngTemplateOutlet="error; context: game"></ng-container
    ></ng-container>
  </ng-container>
</ng-container>
<ng-template #gameTemplate let-game="game">
  <table>
    <tr>
      <td>
        <app-turn-indicator
          [result]="game.result"
          [turn]="game.turn"
          [check]="game.check"
          [player]="game.playingAs"
        >
        </app-turn-indicator>
      </td>
      <td></td>
    </tr>
    <tr>
      <td>
        <p>
          <app-user
            [user]="bottomPlayer(game) !== Color.Dark ? game.players.dark : game.players.light"
          >
          </app-user>
        </p>
        <mat-divider></mat-divider>
        <app-capture-tray
          [captures]="bottomPlayer(game) !== Color.Dark ? game.captures.dark : game.captures.light"
        >
        </app-capture-tray>
      </td>
      <td>
        <button
          mat-icon-button
          [matMenuTriggerFor]="menu"
          aria-label="Game menu"
          class="menu"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu" id="gameMenu">
          <button mat-menu-item (click)="openSettings()">
            <mat-icon>settings</mat-icon>
            <span>Settings</span>
          </button>
          <button mat-menu-item disabled>
            <mat-icon>wash</mat-icon>
            <!-- REVIEW: Remove the water drops -->
            <span>Offer Draw</span>
          </button>
        </mat-menu>
      </td>
    </tr>
    <tr>
      <td>
        <app-game-board
          [snapshot]="game"
          [bottomPlayer]="bottomPlayer(game)"
        ></app-game-board>
      </td>
      <td>
        <!-- REVIEW: Disable for two player games
                <button mat-stroked-button [disabled]="!gameService.canUndo" (click)="onClickUndo()">UNDO</button>
                <button mat-stroked-button [disabled]="!gameService.canRedo" (click)="onClickRedo()">REDO</button>
                -->
        <app-history-control [history]="game.history"></app-history-control>
        <p *ngIf="game.result !== '*'">{{game.result}}</p>
      </td>
    </tr>
    <tr>
      <td>
        <p>
          <app-user
            [user]="bottomPlayer(game) === Color.Dark ? game.players.dark : game.players.light"
          >
          </app-user>
        </p>
        <mat-divider></mat-divider>
        <app-capture-tray
          [captures]="bottomPlayer(game) === Color.Dark ? game.captures.dark : game.captures.light"
        ></app-capture-tray>
      </td>
      <td></td>
    </tr>
  </table>
</ng-template>
<ng-template #loading>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>
<ng-template #error let-error="error">
  <p class="warn">Error: {{error}}</p>
</ng-template>

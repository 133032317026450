import { Component, OnInit, ViewChild } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";

import { MatDialogRef } from "@angular/material/dialog";
import { MatStep, MatStepper } from "@angular/material/stepper";

import {
  FirestoreError,
  FirestoreService,
  Game,
  observableOnSnapshot,
  SERVER_NOW,
} from "../firestore.service";
import { UciRegistryService } from "../uci-registry.service";
import { UciEngineOption, UciEngineOptionType } from "../uci.service";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { StepperSelectionEvent } from "@angular/cdk/stepper";
import { GeocoderService } from "../geocoder.service";
import { FirebaseAuthService } from "../firebase-auth.service";
import { ChessServerService } from "../chess-server.service";
import { filter, map, take } from "rxjs/operators";

export interface NewGameComponentResults {
  readonly onGameCreated?: (gameID: string) => void;
  readonly game: Game;
}

@Component({
  selector: "app-new-game",
  templateUrl: "./new-game.component.html",
  styleUrls: ["./new-game.component.scss"],
})
export class NewGameComponent implements OnInit {
  public computerGame: boolean | undefined;
  public engineOptions: UciEngineOption[];

  public gameTypeCompleted: boolean;
  public engineCompleted: boolean;
  public opponentCompleted: boolean;

  public playAs: "white" | "black" | "random" | undefined;

  public readonly invitationCode;
  public opponentMessage: string;

  public readonly gameInfoForm: FormGroup;
  public readonly engineForm: FormGroup;
  public readonly opponentForm: FormGroup;

  private opponentUid: string;

  @ViewChild(MatStepper)
  private stepper!: MatStepper;

  @ViewChild("stepGameTypeStep")
  private stepGameTypeStep!: MatStep;

  @ViewChild("opponentStep")
  private opponentStep!: MatStep;

  constructor(
    private chessServer: ChessServerService,
    public uciRegistry: UciRegistryService,
    private firestore: FirestoreService,
    private auth: FirebaseAuthService,
    private geocoder: GeocoderService,
    private dialogRef: MatDialogRef<NewGameComponent>,
    private fb: FormBuilder,
    private router: Router,
    private location: Location
  ) {
    this.computerGame = undefined;
    this.engineOptions = [];

    this.gameTypeCompleted = false;
    this.engineCompleted = false;
    this.opponentCompleted = false;

    this.playAs = undefined;

    this.invitationCode = randomId();
    this.opponentMessage = "";
    this.opponentUid = "";

    this.gameInfoForm = this.fb.group({
      event: ["?"],
      site: ["?"],
      round: ["-"],
      tags: this.fb.array([]),
    });

    this.engineForm = this.fb.group({
      engine: [""],
      engineOptions: this.fb.array([]),
    });

    this.opponentForm = this.fb.group({
      invitation: [this.invitationCode],
    });
  }

  ngOnInit(): void {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => void this.calcDefaultSite(position)
      );
    }
  }

  onStepperSelectionChange(event: StepperSelectionEvent): void {
    if (event.selectedStep === this.opponentStep) {
      void this.createWaitingRoom();
    }
  }

  private async calcDefaultSite(position: GeolocationPosition): Promise<void> {
    if (this.gameInfoForm.get("site")?.dirty) {
      return;
    }

    let locality: string | undefined;
    let administrative_area_level_1: string | undefined;
    let country: string | undefined;

    try {
      const results = await this.geocoder.geocode(
        position.coords.latitude,
        position.coords.longitude
      );

      for (const result of results) {
        for (const addressComponent of result.address_components) {
          for (const type of addressComponent.types) {
            switch (type) {
              case "locality":
                locality = addressComponent.long_name;
                break;

              case "administrative_area_level_1":
                administrative_area_level_1 = addressComponent.short_name;
                break;

              case "country":
                country = addressComponent.short_name;
                break;
            }
          }
        }
      }
    } catch (err: unknown) {
      console.log("Error geocoding", err);
    }

    if (
      locality !== undefined &&
      administrative_area_level_1 !== undefined &&
      country !== undefined
    ) {
      const site = `${locality}, ${administrative_area_level_1} ${country}`;
      if (!this.gameInfoForm.get("site")?.dirty) {
        this.gameInfoForm.get("site")?.setValue(site);
      }
    }
  }

  private async createWaitingRoom(): Promise<void> {
    const uid = this.auth.currentUser?.uid;
    if (uid === undefined) {
      this.opponentMessage = "Error: must sign in to create a game!";
      return;
    }

    try {
      await this.firestore.waitingRoomDoc(this.invitationCode).set(
        {
          createdBy: uid,
          createdAt: SERVER_NOW,

          player2: null,
          player2JoinedAt: null,

          gameId: null,
          gameStartTime: null,
        },
        { merge: false }
      );

      this.opponentMessage = "Waiting for opponent to join...";
      return await this.waitForOpponent();
    } catch (err: unknown) {
      this.opponentMessage = "Error: " + (err as FirestoreError).message;
    }
  }

  private async waitForOpponent(): Promise<void> {
    return observableOnSnapshot(
      this.firestore.waitingRoomDoc(this.invitationCode)
    )
      .pipe(
        map((snapshot) => snapshot.data()),
        filter(isNotNullOrUndefined),
        map((data) => data.player2),
        filter(isNotNullOrUndefined),
        take(1)
      )
      .forEach((uid) => {
        // REVIEW: Use friendly names! Also move the display logic into the template
        this.opponentUid = uid;
        this.opponentMessage = "Player " + this.opponentUid + " joined!";
        this.opponentCompleted = true;
      });
  }

  get joinUrl(): string {
    const urlTree = this.router.createUrlTree(["/join", this.invitationCode]);
    const serialized = this.router.serializeUrl(urlTree);
    const prepared = this.location.prepareExternalUrl(serialized);
    const origin = window.location.origin;

    return origin + prepared;
  }

  inputType(optionType: UciEngineOptionType): string {
    switch (optionType) {
      case "check":
      case "combo":
      case "button":
        break;

      case "spin":
        return "number";

      case "string":
        return "text";
    }

    throw new Error(
      `Not a valid type for input: ${optionType ?? "<undefined>"}`
    );
  }

  inputMin(
    optionType: UciEngineOptionType,
    minMax: number | undefined
  ): string {
    if (optionType === "spin") {
      if (typeof minMax !== "undefined") {
        return minMax.toString();
      }
    }

    return "";
  }

  onClickGameType(computer: boolean): void {
    this.computerGame = computer;
    this.gameTypeCompleted = true;
    this.stepGameTypeStep.completed = true;
    this.stepper.next();
  }

  onClickOption(optionName: string): void {
    // REVIEW: NYI
    console.log("NYI - onClickOption", optionName);
  }

  async updateEngineOptions(engineId: string): Promise<void> {
    // REVIEW: Preserve the settings across engine changes (or the same engine being selected again)
    const uci = this.uciRegistry.createEngine(engineId);
    try {
      const engineInfo = await uci.uci();

      this.engineOptions = engineInfo.options;
      const formOptions = this.engineForm.get("engineOptions") as FormArray;
      formOptions.clear();

      for (const option of this.engineOptions) {
        let def: string | number | boolean = "";
        const validators: ValidatorFn[] = [];
        if (typeof option.default !== "undefined") {
          switch (option.type) {
            case "check":
              def = option.default === "true" || option.default === true;
              break;

            case "spin":
              def = option.default;
              if (typeof option.min !== "undefined") {
                validators.push(Validators.min(option.min));
              }
              if (typeof option.max !== "undefined") {
                validators.push(Validators.max(option.max));
              }
              break;

            case "combo":
              def = option.default;
              break;

            case "button":
              def = option.default === "true"; // REVIEW: What do button type have?
              break;

            case "string":
              def = option.default;
              break;
          }
        }

        formOptions.push(this.fb.control(def, validators));
      }

      uci.onDestroy();
      this.engineCompleted = true;
    } catch (err: unknown) {
      console.log("Error from UCI", err);
    }
  }

  async onStart(): Promise<void> {
    try {
      const authUser = this.auth.currentUser;
      if (authUser === null) {
        throw new Error("Must be authenticated!");
      }

      let playAs = this.playAs;
      if (playAs === "random") {
        if (Math.floor(Math.random() * 2 + 1) === 0) {
          playAs = "white";
        } else {
          playAs = "black";
        }
      }

      let otherUserId = this.opponentUid;
      if (this.computerGame) {
        otherUserId = this.engineForm.get("engine")?.value as string;
      }

      const game: Game = {
        id: "",
        public: true, // REVIEW
        engineOptions: undefined,
        event: this.gameInfoForm.get("event")?.value as string,
        site: this.gameInfoForm.get("site")?.value as string,
        date: SERVER_NOW,
        round: this.gameInfoForm.get("round")?.value as string,
        result: "*",
        tags: {}, // REVIEW
        white: playAs === "white" ? authUser.uid : otherUserId,
        black: playAs === "black" ? authUser.uid : otherUserId,
        lastMoveDate: null,
      };

      if (this.computerGame) {
        const engineOptions:
          | Array<{ name: string; value: string | number | boolean }>
          | undefined = [];
        const formOptions = this.engineForm.get("engineOptions") as FormArray;
        this.engineOptions.forEach((option, index) => {
          const formField = formOptions.get([index]);
          if (formField !== null) {
            if (formField.dirty) {
              let value: undefined | string | number | boolean;
              if (typeof formField.value === "boolean") {
                value = formField.value;
              } else if (typeof formField.value === "number") {
                value = formField.value;
              } else if (typeof formField.value === "string") {
                value = formField.value;
              }

              if (value !== undefined) {
                engineOptions.push({
                  name: option.name,
                  value,
                });
              } else {
                throw Error(
                  "Unexpect form field value type: " + typeof formField.value
                );
              }
            }
          }
        });

        if (engineOptions.length > 0) {
          game.engineOptions = engineOptions;
        }
      }

      // Create the game document
      const gameId = await this.chessServer.createNewGame(game);

      // Update the waiting room
      if (!this.computerGame) {
        await this.firestore.waitingRoomDoc(this.invitationCode).set(
          {
            gameId,
            gameStartTime: SERVER_NOW,
          },
          { merge: true }
        );
      }

      // The home component will redirect to this game id
      this.dialogRef.close(gameId);
    } catch (err: unknown) {
      // REVIEW: Snackbar or something to show the error
      console.log("Error during onStart for new game", err);
    }
  }
}

function randomId(): string {
  const array = new Uint8Array(16);
  window.crypto.getRandomValues(array);

  return Array.from(array, function (byte) {
    return ("0" + (byte & 0xff).toString(16)).slice(-2);
  }).join("");
}

function isNotNullOrUndefined<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

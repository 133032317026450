<ng-container *ngIf="auth.user | async; else signinTemplate">
  <span *ngIf="auth.profile | async; let profile"
    ><a mat-button [routerLink]="'/profile/' + profile?.id"
      >Welcome {{safeDisplayName(profile)}}</a
    >
    <button
      mat-icon-button
      aria-label="Logout"
      (click)="signout()"
      matTooltip="Logout"
    >
      <img
        *ngIf="profile?.photoURL !== null"
        class="avatar"
        src="{{profile?.photoURL}}"
      />
      <mat-icon *ngIf="profile?.photoURL === null">account_circle</mat-icon>
    </button>
  </span>
</ng-container>
<ng-template #signinTemplate>
  <button
    mat-icon-button
    aria-label="Sign in"
    (click)="signin()"
    matTooltip="Sign in or register"
  >
    <mat-icon>login</mat-icon>
  </button>
</ng-template>

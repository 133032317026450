import { Component, Input } from "@angular/core";
import { Piece } from "../game.service";

@Component({
  selector: "app-capture-tray",
  templateUrl: "./capture-tray.component.html",
  styleUrls: ["./capture-tray.component.scss"],
})
export class CaptureTrayComponent {
  @Input()
  captures: Piece[];

  // REVIEW: should we sort by value?
  constructor() {
    this.captures = [];
  }

  get points(): number {
    let pts = 0;
    for (const piece of this.captures) {
      pts += piece.value;
    }

    return pts;
  }
}

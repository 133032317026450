import { Component, OnDestroy, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs";

import { DarkModeListenerService } from "./dark-mode-listener.service";

const themes = {
  dark: "purple-green.css",
  light: "deep-purple.css",
};

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  // REVIEW: May need to store these tabs in cookies so they don't get lost between visits
  public tabs = [
    {
      path: "/",
      title: "Home",
    },
  ];

  private subscriptions?: Subscription;

  // REVIEW: Implement closing tabs
  // https://stackblitz.com/edit/mat-tabs-closing-example?file=app%2Ftab-group-basic-example.html

  constructor(
    private router: Router,
    private darkModeService: DarkModeListenerService
  ) {}

  ngOnInit(): void {
    this.subscriptions = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Add to open tabs if not already present
        for (const tab of this.tabs) {
          if (tab.path === event.urlAfterRedirects) {
            return; // nothing more to do
          }
        }

        if (event.urlAfterRedirects.startsWith("/game/")) {
          this.tabs.push({
            path: event.urlAfterRedirects,
            title: `Game ${this.tabs.length}`,
          });
        }
      }
    });

    this.subscriptions.add(
      this.darkModeService.darkMode.subscribe((theme) => {
        this.setTheme(theme);
      })
    );
  }

  ngOnDestroy(): void {
    if (typeof this.subscriptions !== "undefined") {
      this.subscriptions.unsubscribe();
    }
  }

  isActivePath(path: string): boolean {
    return this.router.isActive(path, true);
  }

  setTheme(theme: keyof typeof themes): void {
    // find the link element
    const stylesheets = Object.values(themes);
    const links = document.head.querySelectorAll('link[rel="stylesheet"]');
    links.forEach((value: Element) => {
      const href = value.getAttribute("href");
      if (stylesheets.includes(href ?? "") && href !== themes[theme]) {
        value.setAttribute("href", themes[theme]);
      }
    });
  }
}

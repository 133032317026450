<h1 mat-dialog-title>{{title()}}</h1>
<mat-dialog-content>
  <ng-container [ngSwitch]="mode">
    <ng-container *ngSwitchCase="'signin'">
      <form [formGroup]="form" class="form">
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Email</mat-label>
          <input
            matInput
            type="email"
            formControlName="email"
            placeholder="Email"
            required
          />
          <mat-icon matSuffix>alternate_email</mat-icon>
          <mat-hint>Email address.</mat-hint>
          <mat-error *ngIf="fieldError('email')"
            >{{fieldErrorMessage('email')}}</mat-error
          >
        </mat-form-field>

        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Password</mat-label>
          <input
            matInput
            type="password"
            formControlName="password"
            placeholder="Password"
            required
          />
          <mat-icon matSuffix>lock</mat-icon>
          <mat-hint>Password.</mat-hint>
          <mat-error *ngIf="fieldError('password')"
            >{{fieldErrorMessage('password')}}</mat-error
          >
        </mat-form-field>
      </form>
      <div>
        <a mat-button (click)="setMode('register')">REGISTER</a>
        <a mat-button (click)="setMode('forgot password')">FORGOT PASSWORD</a>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'register'">
      <form [formGroup]="form" class="form">
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Email</mat-label>
          <input
            matInput
            type="email"
            formControlName="email"
            placeholder="Email"
            required
          />
          <mat-icon matSuffix>alternate_email</mat-icon>
          <mat-hint>Email address.</mat-hint>
          <mat-error *ngIf="fieldError('email')"
            >{{fieldErrorMessage('email')}}</mat-error
          >
        </mat-form-field>

        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Password</mat-label>
          <input
            matInput
            type="password"
            formControlName="password"
            placeholder="Password"
            required
          />
          <mat-icon matSuffix>lock</mat-icon>
          <mat-hint>Password.</mat-hint>
          <mat-error *ngIf="fieldError('password')"
            >{{fieldErrorMessage('password')}}</mat-error
          >
        </mat-form-field>

        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Confirm password</mat-label>
          <input
            matInput
            type="password"
            formControlName="confirm"
            placeholder="Confirm Password"
            required
          />
          <mat-icon matSuffix>lock</mat-icon>
          <mat-hint>Reenter password.</mat-hint>
          <mat-error *ngIf="fieldError('confirm')"
            >{{fieldErrorMessage('confirm')}}</mat-error
          >
        </mat-form-field>

        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Display name</mat-label>
          <input
            matInput
            type="text"
            formControlName="displayName"
            placeholder="Name"
          />
          <mat-icon matSuffix>person</mat-icon>
          <mat-hint>Enter the name others will see.</mat-hint>
          <mat-error *ngIf="fieldError('displayName')"
            >{{fieldErrorMessage('displayName')}}</mat-error
          >
        </mat-form-field>

        <mat-slide-toggle formControlName="public"
          >Show profile to other players</mat-slide-toggle
        >
      </form>
      <div>
        <a mat-button (click)="setMode('signin')">SIGNIN</a>
        <a mat-button (click)="setMode('forgot password')">FORGOT PASSWORD</a>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'forgot password'">
      <form [formGroup]="form" class="form">
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Email</mat-label>
          <input
            matInput
            type="email"
            formControlName="email"
            placeholder="Email"
            required
          />
          <mat-icon matSuffix>alternate_email</mat-icon>
          <mat-hint>Email address.</mat-hint>
          <mat-error *ngIf="fieldError('email')"
            >{{fieldErrorMessage('email')}}</mat-error
          >
        </mat-form-field>
      </form>
      <div>
        <a mat-button (click)="setMode('signin')">SIGNIN</a>
        <a mat-button (click)="setMode('register')">REGISTER</a>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'password reset'">
      <form [formGroup]="form" class="form">
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Email</mat-label>
          <input
            matInput
            type="email"
            formControlName="email"
            placeholder="Email"
            readonly
          />
          <mat-icon matSuffix>alternate_email</mat-icon>
          <mat-hint>Email address.</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Password</mat-label>
          <input
            matInput
            type="password"
            formControlName="password"
            placeholder="Password"
            required
          />
          <mat-icon matSuffix>lock</mat-icon>
          <mat-hint>Password.</mat-hint>
          <mat-error *ngIf="fieldError('password')"
            >{{fieldErrorMessage('password')}}</mat-error
          >
        </mat-form-field>

        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Confirm password</mat-label>
          <input
            matInput
            type="password"
            formControlName="confirm"
            placeholder="Confirm Password"
            required
          />
          <mat-icon matSuffix>lock</mat-icon>
          <mat-hint>Reenter password.</mat-hint>
          <mat-error *ngIf="fieldError('confirm')"
            >{{fieldErrorMessage('confirm')}}</mat-error
          >
        </mat-form-field>
      </form>
      <div>
        <a mat-button (click)="setMode('forgot password')"
          >RESEND RESET EMAIL</a
        >
      </div>
    </ng-container>
  </ng-container>
  <div *ngIf="formError()" class="warn">
    <mat-icon inline="true" color="warn">error</mat-icon>{{formErrorMessage()}}
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-stroked-button mat-dialog-close id="cancel">CANCEL</button>
  <button mat-flat-button color="primary" (click)="ok()" id="ok">OK</button>
</mat-dialog-actions>

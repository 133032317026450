<h1 mat-dialog-title>Upload image file for profile picture</h1>
<mat-dialog-content>
  <div class="wrapper">
    <div>
      <p>{{fileName}}<br />{{fileSize}}</p>
    </div>
    <div>
      <p *ngIf="error !== null" class="warn">{{error}}</p>
      <img *ngIf="previewURL !== null" [src]="previewURL" />
    </div>
  </div>
  <input
    #fileInput
    (change)="onFileSelected(fileInput.files)"
    type="file"
    id="fileInput"
    accept="image/*"
    capture="user"
  />
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-stroked-button mat-dialog-close>Close</button>
  <button mat-stroked-button (click)="fileInput.click()">Choose File</button>
  <button
    *ngIf="fileName.length > 0"
    mat-stroked-button
    color="primary"
    (click)="upload(fileInput.files)"
  >
    Upload
  </button>
</mat-dialog-actions>

import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Settings } from "../game-options.service";

@Component({
  selector: "app-settings",
  templateUrl: "./settings-dialog.component.html",
  styleUrls: ["./settings-dialog.component.scss"],
})
export class SettingsDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: Settings) {}
}

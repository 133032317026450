import { Injectable, OnDestroy } from "@angular/core";

import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

import {
  Game as GameCore,
  createGame,
  computeMovesForSquare,
  movePiece,
  Square as SquareCore,
} from "src/game";

import { UciRegistryService } from "./uci-registry.service";
import { AuthenticationService } from "./authentication.service";
import { combineLatest, from, Observable } from "rxjs";
import {
  File,
  FirestoreService,
  Game,
  observableOnSnapshot,
  Result,
  SERVER_NOW,
  User,
} from "./firestore.service";
import { map, share, switchMap, tap } from "rxjs/operators";
import { GameSnapshot, Label } from "./game-update";
import { UciService } from "./uci.service";

export class ChessError extends Error {
  constructor(message: string) {
    super();

    this.name = "ChessError";
    this.message = message;
  }
}

export enum Color {
  Dark = "DARK",
  Light = "LIGHT",
}

export enum PieceName {
  Pawn = "P",
  Knight = "N",
  Bishop = "B",
  Rook = "R",
  Queen = "Q",
  King = "K",
}

export interface Piece {
  readonly piece: PieceName;
  readonly color: Color;
  readonly value: number;
}

export interface Move {
  readonly notation: string;
}

export function labelFromSquare(file: string, rank: number): Label;
export function labelFromSquare(file: number, rank: number): Label;
export function labelFromSquare(file: unknown, rank: number): Label {
  let fileNumber: number;
  if (typeof file === "string") {
    fileNumber = file.toLowerCase().charCodeAt(0) - "a".charCodeAt(0) + 1;
  } else if (typeof file === "number") {
    fileNumber = file;
  } else {
    throw new Error("Invalid arguments. File must be a string or a number.");
  }

  if (fileNumber < 1 || fileNumber > 8) {
    throw new ChessError(
      `File ${fileNumber} is out of bounds. 1 <= file <= 8 (or a <= file <= g`
    );
  }

  // Make it zero based
  fileNumber -= 1;

  if (rank < 1 || rank > 8) {
    throw new ChessError(`Rank ${rank} is out of bounds. 1 <= file <= 8`);
  }

  return (String.fromCharCode("a".charCodeAt(0) + fileNumber) +
    rank.toString()) as Label;
}

export function crackLabel(label: Label): { file: number; rank: number } {
  return {
    file: label.charCodeAt(0) - "a".charCodeAt(0) + 1,
    rank: label.charCodeAt(1) - "0".charCodeAt(0),
  };
}

@Injectable({
  providedIn: "root",
})
export class GameService implements OnDestroy {
  private activeUciEngines: Record<
    string,
    { lastMove: number; engine: UciService }
  >;

  constructor(
    private auth: AuthenticationService,
    private firestore: FirestoreService,
    private uciRegistryService: UciRegistryService
  ) {
    this.activeUciEngines = {};
  }

  ngOnDestroy(): void {
    for (const gameID of Object.keys(this.activeUciEngines)) {
      this.activeUciEngines[gameID].engine.onDestroy();
    }
  }

  observeGame(gameID: string): Observable<GameSnapshot> {
    const gameDocRef = this.firestore.gameDoc(gameID);
    let gameDoc: Game;

    // The sequence is a bit odd here, but it's designed to limit
    // the amount of updates coming from the DB during game play.
    // 1. We fetch the game document (don't even observe it, just
    // a single fetch). We do this becuase it needs to be early to
    // get the user ids, but it also changes on each move - but those
    // changes are just denormalized updates from the moves collection.
    // 2. We then observe 4 things in parellel so we don't create cycles
    // 2.a The user authenticated with firebase auth (current signed in user)
    // 2.b The user document for the user playing black
    // 2.c The user document for the user playing white
    // 2.d The move collection
    // 3. We then merge all of this into a GameSnapshot
    // 4. Which we tap() in order to run the UCI in parellel for a
    // single player game.
    // 5. Finally we publish to a Subject with ref counting.

    return from(gameDocRef.get()).pipe(
      map((snapshot) => {
        // Step 1 - Map to the game document data
        const data = snapshot.data();
        if (data !== undefined) {
          return data;
        } else {
          throw new ChessError("Game not found");
        }
      }),
      tap((value) => {
        // Set the game aside to use later.
        // REVIEW: We should create a "side state" observable
        // that makes it easy to pass extra properties along with
        // a "main" observable. New "withProperties" operator?
        gameDoc = value;
      }),
      switchMap((game) => {
        // This is step 2 above
        return combineLatest([
          this.auth.user,
          this.observeUser(game.white as string),
          this.observeUser(game.black as string),
          this.observeMovesAsGame(gameDocRef),
        ]);
      }),
      map((args) => {
        // Step 3 from above
        const currentUser = args[0];
        const playerForWhite = args[1];
        const playerForBlack = args[2];
        const game = args[3];

        return this.createGameSnapshot(
          currentUser,
          gameDoc,
          playerForWhite,
          playerForBlack,
          game
        );
      }),
      tap((snapshot) => {
        // Step 4
        this.processUCI(snapshot);
      }),
      share() // Step 5
    );
  }

  private observeUser(userID: string): Observable<User> {
    return observableOnSnapshot(this.firestore.userDoc(userID)).pipe(
      map((snapshot) => {
        const user = snapshot.data();
        if (user === undefined) {
          throw new ChessError(`DB error, user ${userID} is missing`);
        }

        return user;
      })
    );
  }

  private observeMovesAsGame(
    gameDocRef: firebase.firestore.DocumentReference<Game>
  ): Observable<GameCore> {
    return observableOnSnapshot(
      this.firestore.gameMoves(gameDocRef).orderBy("ordinal")
    ).pipe(
      map((querySnapshot) => {
        return querySnapshot.docs.map((snapshot) => snapshot.data());
      }),
      map((moves) => {
        const game = this.createGame();
        for (const move of moves) {
          const valid = this.movePiece(
            game,
            move.from.file.charCodeAt(0) - "a".charCodeAt(0),
            move.from.rank - 1,
            move.to.file.charCodeAt(0) - "a".charCodeAt(0),
            move.to.rank - 1
          );
          if (!valid) {
            console.log("invalid move loaded", move);
            throw new ChessError(
              "Invalid move loaded from Firestore: " + JSON.stringify(move)
            );
          }
        }
        return game;
      })
    );
  }

  private createGameSnapshot(
    currentUser: firebase.User | null,
    gameDoc: Game,
    playerForWhite: User,
    playerForBlack: User,
    game: GameCore
  ): GameSnapshot {
    // eslint-disable-next-line no-restricted-syntax
    const lastMove =
      game.history.length > 0 ? game.history[game.history.length - 1] : null;

    const playingAs =
      currentUser?.uid === playerForBlack.id
        ? Color.Dark
        : currentUser?.uid === playerForWhite.id
        ? Color.Light
        : null;

    const result = (lastMove?.result ?? "*") as Result;

    const turn =
      result !== "*"
        ? null
        : game.history.length % 2 === 1
        ? Color.Dark
        : Color.Light;

    const opponent =
      playingAs === Color.Dark
        ? playerForWhite
        : playingAs === Color.Light
        ? playerForBlack
        : null;

    const squares = this.computeSquares(game);
    const moves: Partial<Record<Label, Label[]>> = this.computeMoves(game);
    const attackers: Partial<Record<Label, Label[]>> = this.computeCover(game);

    const snapshot: GameSnapshot = {
      canUndo: false,
      canRedo: false,
      captures: {
        dark: game.captures[1].map(
          (p) => new PieceImpl(p.piece, p.dark ? Color.Dark : Color.Light)
        ),
        light: game.captures[0].map(
          (p) => new PieceImpl(p.piece, p.dark ? Color.Dark : Color.Light)
        ),
      },
      check: lastMove?.check ?? false,
      engineOptions: gameDoc.engineOptions,
      history: game.history.map((value) => {
        return {
          from: label(value.fromFile, value.fromRank),
          to: label(value.toFile, value.toRank),
          notation: value.notation,
        };
      }),
      id: gameDoc.id,
      players: {
        dark: playerForBlack,
        light: playerForWhite,
      },
      playingAs: playingAs,
      result: result,
      turn: turn,

      squares: squares,
      moves: moves,
      attackers: attackers,

      move: (
        fromFile: number,
        fromRank: number,
        toFile: number,
        toRank: number
      ) => {
        if (
          !(
            playingAs !== null &&
            (playingAs === turn || opponent?.uci !== null)
          )
        ) {
          return false;
        }

        return this.move(fromFile, fromRank, toFile, toRank, gameDoc.id, game);
      },

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      undo: () => {
        throw new ChessError("Not implemented");
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      redo: () => {
        throw new ChessError("Not implemented");
      },
    };

    return snapshot;
  }

  private computeSquares(game: GameCore): Partial<Record<Label, Piece>> {
    const squares: Partial<Record<Label, Piece>> = {};
    for (let file = 0; file < 8; file++) {
      for (let rank = 0; rank < 8; rank++) {
        const p = game.board[file][rank];
        if (p !== null) {
          const square = label(file, rank);
          squares[square] = new PieceImpl(
            p.piece,
            p.dark ? Color.Dark : Color.Light
          );
        }
      }
    }

    return squares;
  }

  private computeMoves(game: GameCore): Partial<Record<Label, Label[]>> {
    const ret: Partial<Record<Label, Label[]>> = {};
    for (let file = 0; file < 8; file++) {
      for (let rank = 0; rank < 8; rank++) {
        const moves = this.computeMovesForSquare(game, file, rank, false);
        if (moves.length > 0) {
          ret[label(file, rank)] = moves.map((square) =>
            label(square.file, square.rank)
          );
        }
      }
    }

    return ret;
  }

  private computeCover(game: GameCore): Partial<Record<Label, Label[]>> {
    const attackers: Partial<Record<Label, Label[]>> = {};

    for (let attackingFile = 0; attackingFile < 8; attackingFile++) {
      for (let attackingRank = 0; attackingRank < 8; attackingRank++) {
        const piece = game.board[attackingFile][attackingRank];
        if (piece !== null) {
          const moves = this.computeMovesForSquare(
            game,
            attackingFile,
            attackingRank,
            false
          );

          const attackingLabel = label(attackingFile, attackingRank);
          for (const move of moves) {
            // Skip pawns moving directly forward
            if (piece.piece === "P" && attackingFile === move.file) {
              continue;
            }

            const l = label(move.file, move.rank);
            if (typeof attackers[l] === "undefined") {
              attackers[l] = [attackingLabel];
            } else {
              attackers[l]?.push(attackingLabel);
            }
          }
        }
      }
    }

    return attackers;
  }

  private move(
    fromFile: number,
    fromRank: number,
    toFile: number,
    toRank: number,
    gameID: string,
    game: GameCore
  ): boolean {
    if (
      !this.movePiece(game, fromFile - 1, fromRank - 1, toFile - 1, toRank - 1)
    ) {
      // Bad move
      console.warn("movePiece failed");
      return false;
    }

    // Record the move in Firestore
    void this.recordMove(gameID, game, fromFile, fromRank, toFile, toRank);

    return true;
  }

  private async recordMove(
    gameID: string,
    game: GameCore,
    fromFile: number,
    fromRank: number,
    toFile: number,
    toRank: number
  ): Promise<void> {
    try {
      const lastMove = game.history[game.history.length - 1];
      const gameRef = this.firestore.gameDoc(gameID);

      // We use a batched write so the game doc updates at the same time
      // as the new move document is created
      const batch = this.firestore.batch();
      const ordinal = game.history.length;

      batch.set(this.firestore.gameMoves(gameRef).doc(ordinal.toString()), {
        ordinal: ordinal,
        date: SERVER_NOW,
        from: {
          file: fileFromString(
            String.fromCharCode("a".charCodeAt(0) + fromFile - 1)
          ),
          rank: fromRank,
        },
        to: {
          file: fileFromString(
            String.fromCharCode("a".charCodeAt(0) + toFile - 1)
          ),
          rank: toRank,
        },
        notation: lastMove.notation,
      });

      // Update the game lastMove and lastMoveDate fields
      const updates: {
        lastMove: string;
        lastMoveDate: Date;
        result?: string;
      } = {
        lastMove:
          Math.floor((ordinal + 1) / 2).toString() +
          (ordinal % 2 === 1 ? ". " : "... ") +
          lastMove.notation,
        lastMoveDate: SERVER_NOW,
      };

      if (lastMove.result !== null) {
        updates.result = lastMove.result;
      }

      batch.update(gameRef, updates);

      await batch.commit();
    } catch (reason) {
      console.log("Error commiting batch", reason);
      // REVIEW: Better error message
      // REVIEW: How to get this error propogated out
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      //this.snackBar.open(`Error contacting server: ${reason}`, "Close");
    }
  }

  private processUCI(snapshot: GameSnapshot): void {
    // Is this a UCI turn?
    // Game is on-going, authenticated user is one of the players,
    // other player is UCI, and it is the UCI players turn
    const currentPlayer =
      snapshot.turn === Color.Dark
        ? snapshot.players.dark
        : snapshot.turn === Color.Light
        ? snapshot.players.light
        : null;
    const uciLength = currentPlayer?.uci?.length ?? 0;
    if (snapshot.playingAs !== null && uciLength !== 0) {
      // This inner if does not change the logic, just
      // makes the complier happy.
      if (currentPlayer?.uci) {
        void this.uciTurn(snapshot, currentPlayer.uci);
      }
    }
  }

  private async uciTurn(
    snapshot: GameSnapshot,
    engineID: string
  ): Promise<void> {
    let engineStatus = this.activeUciEngines[snapshot.id];
    if (engineStatus === undefined) {
      engineStatus = {
        lastMove: -1,
        engine: this.uciRegistryService.createEngine(engineID),
      };

      // Initialize the engine in UCI mode
      await engineStatus.engine.uci();

      // Set options
      if (typeof snapshot.engineOptions !== "undefined") {
        for (const opt of snapshot.engineOptions) {
          engineStatus.engine.setoption(opt.name, opt.value);
        }
      }

      // isready?
      await engineStatus.engine.isready();

      // start a new game
      await engineStatus.engine.ucinewgame();

      if (this.activeUciEngines[snapshot.id] === undefined) {
        this.activeUciEngines[snapshot.id] = engineStatus;
      } else {
        // Another execution thread created the engine first
        engineStatus.engine.onDestroy();
        engineStatus = this.activeUciEngines[snapshot.id];
      }
    }

    // Have we already made this move?
    if (engineStatus.lastMove < snapshot.history.length) {
      engineStatus.lastMove = snapshot.history.length;

      // REVIEW: I'm not 100% sure how the startpos parameter should be used
      const moves = snapshot.history.map((move) => `${move.from}${move.to}`);
      engineStatus.engine.position(moves, undefined, true);

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [bestMove, ponder] = await engineStatus.engine.go();
      snapshot.move(
        bestMove.charCodeAt(0) - "a".charCodeAt(0) + 1,
        parseInt(bestMove.substring(1, 2)),
        bestMove.charCodeAt(2) - "a".charCodeAt(0) + 1,
        parseInt(bestMove.substring(3, 4))
      );
    }
  }

  // These next 3 functions (movePiece, computeMovesForSquare, and createGame)
  // are only here so that I can mock them with Jasmine. I could not make mocks
  // on global imported functions work.
  private movePiece(
    game: GameCore,
    fromFile: number,
    fromRank: number,
    toFile: number,
    toRank: number,
    validateOnly?: boolean
  ): boolean {
    return movePiece(game, fromFile, fromRank, toFile, toRank, validateOnly);
  }

  private computeMovesForSquare(
    game: GameCore,
    file: number,
    rank: number,
    strict: boolean = true
  ): SquareCore[] {
    // This method is only here so we can mock it.
    return computeMovesForSquare(game, file, rank, strict);
  }

  private createGame(): GameCore {
    return createGame();
  }
}

class PieceImpl implements Piece {
  private _piece: PieceName;
  private _color: Color;

  constructor(piece: "P" | "N" | "B" | "R" | "Q" | "K", color: Color) {
    this._color = color;

    switch (piece) {
      case "P":
        this._piece = PieceName.Pawn;
        break;

      case "N":
        this._piece = PieceName.Knight;
        break;

      case "B":
        this._piece = PieceName.Bishop;
        break;

      case "R":
        this._piece = PieceName.Rook;
        break;

      case "Q":
        this._piece = PieceName.Queen;
        break;

      case "K":
        this._piece = PieceName.King;
        break;

      default:
        throw new ChessError("Invalid piece");
    }
  }

  get piece(): PieceName {
    return this._piece;
  }

  get color(): Color {
    return this._color;
  }

  get value(): number {
    switch (this._piece) {
      case PieceName.Pawn:
        return 1;

      case PieceName.Knight:
      case PieceName.Bishop:
        return 3;

      case PieceName.Rook:
        return 5;

      case PieceName.Queen:
        return 9;

      default:
        throw new ChessError(
          "King does not have a value as it cannot be captured"
        );
    }
  }
}

function label(file: number, rank: number): Label {
  return (String.fromCharCode("a".charCodeAt(0) + file) +
    (rank + 1).toString()) as Label;
}

function fileFromString(file: string): File {
  switch (file) {
    case "a":
      return File.a;

    case "b":
      return File.b;

    case "c":
      return File.c;

    case "d":
      return File.d;

    case "e":
      return File.e;

    case "f":
      return File.f;

    case "g":
      return File.g;

    case "h":
      return File.h;
  }

  throw new ChessError("Invalid file");
}

export const testing = {
  PieceImpl: PieceImpl,
  fileFromString: fileFromString,
  label: label,
};
